import Header from "./Header";
import Footer from "./Footer";
import MarqueeText from "./MarqueeText";
import NavBar from "./NavBar";
import FadeText from "./FadeText";
import BackToList from "./BackToList";

export {
    Header,
    Footer,
    MarqueeText,
    NavBar,
    FadeText,
    BackToList
}