import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
const DetailTop = ({leftBoxText,rightBoxText}) => {
    return (
        <AllContainer >
            <TextBox>
                <LeftText>Write</LeftText>
                <RightText>{leftBoxText}</RightText>
            </TextBox>
            <TextBox>
                <LeftText>Event</LeftText>
                <RightText>{rightBoxText}</RightText>
            </TextBox>
        </AllContainer >
    )
}

const AllContainer = styled.div`
    padding : 0 40px;
    display : flex;
    flex-direction : row;
    gap : 40px;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 0px;
    }

`;

const TextBox = styled.div`
    display : flex;
    flex-direction : row;
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        flex-direction : column;
    }
`;

const LeftText = styled.span`
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default4m.size};
    font-weight : ${fonts.pretendardFont.default4m.weight};
    color :${colors.$444444};
    @media ${props => props.theme.minSmallDesktop} {
        font-size : ${fonts.pretendardFont.default3m.size};
        font-weight : ${fonts.pretendardFont.default3m.weight};
    }
`
const RightText = styled.span`
    font-family : ${fonts.fontFamily.montserrat};
    font-size : ${fonts.montserratFont.english3r.size};
    font-weight : ${fonts.montserratFont.english3r.weight};
    color :${colors.$aaaaaa};
`;


export default DetailTop