import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../constants';
import FadeText from "./FadeText";
import Marquee from 'react-fast-marquee';
import axios from 'axios';

import { ReactComponent as YouTubeIcon } from "../assets/images/svg/ico_youtube.svg";
import { ReactComponent as InstargramIcon } from "../assets/images/svg/ico_instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/images/svg/ico_twitter.svg";
import { Link } from 'react-router-dom';


const Footer = ({ informationSectionRef, changeHeader }) => {

  const [footerImg, setFooterImg] = useState([]);

  const [snsLists, setSnsLists] = useState([
    {
      imgUrl : <TwitterIcon />,
      snsUrl : "https://twitter.com/mammoth_ent"
    },
    {
      imgUrl : <InstargramIcon />,
      snsUrl : "https://www.instagram.com/mammoth_ent?igsh=MXBnbjVrZmNxMWN5aA%3D%3D&utm_source=qr"
    },
    {
      imgUrl : <YouTubeIcon />,
      snsUrl : "https://youtube.com/@mammothent0905?si=8lJkUP3qhqO1JiP2"
    },
  ]);


  const onClickNewTab = (link) => {
    window.open(link, "_blank")
}

  const getFooterImg = async() => {
    try {
      let url = `${process.env.REACT_APP_GMAIL_API}/bottom`
      let result = await axios.get(`${url}`);
      setFooterImg(result?.data?.data)
    } catch (error) {
      
    }
  }

  useEffect(() =>{
    getFooterImg()
  },[])


  return (
    <Outter className="scrollTrigger" ref={informationSectionRef} height={-informationSectionRef?.current?.clientHeight+"px"}>
      <BgContainer>
        <InformationContainer>
          <LeftBox>
            <Title>Information</Title>
            <Content>
              <TopContent>
                <KrAddress>
                  서울특별시 강남구 도산대로28길 26<br />맘모스엔터테인먼트
                </KrAddress>
                <EnAddress>
                  MAMMOTH Entertainment 26, Dosan-daero 28-gil, Gangnam-gu, Seoul, Republic of Korea
                </EnAddress>
              </TopContent>
              <MiddleContent>+82. 02. 518. 0905</MiddleContent>
              <BottomContent>
                <ColumnContent>
                  <ContactContent>
                    <ColumnTopContent>Contact</ColumnTopContent>
                    <ColumnBottomContent>
                      <div>
                        MON - FRI 9AM - 6PM

                      </div>
                      <div>
                        SAT. SUN. HOLIDAY OFF

                      </div>
                    </ColumnBottomContent>
                  </ContactContent>
                  <EmailContent>
                    <ColumnTopContent>Email</ColumnTopContent>
                    <ColumnBottomContent>
                      mammothent0905@gmail.com
                    </ColumnBottomContent>
                  </EmailContent>
                </ColumnContent>
                <LastContent>
                <FooterContent>ⓒ MAMMOTH Entertainment. All rights reserved.</FooterContent>
                <SNSList>
                  {
                    snsLists.map((item, index) => (
                      <div onClick={()=>
                        onClickNewTab(item.snsUrl)
                      }
                      key={index}
                      >
                        {item.imgUrl}
                      </div>
                    ))
                  }
                </SNSList>
                </LastContent>
              </BottomContent>
            </Content>
          </LeftBox>
          <RightBox>
            <MarqueeBox speed={80}>
              {
                footerImg.map((item, index) => (
                  <RightBoxImage src={item.imageUrl} key={index}
                  />
                ))
              }
            </MarqueeBox>
          </RightBox>
        </InformationContainer>
      </BgContainer>
      <FadeComponent>
        <FadeText isVisible={changeHeader} topText={"MAMMOTH"} bottomText={"ENTERTAINMENT"} fontColor={colors.$000000} />
      </FadeComponent>
    </Outter>
  )
}
const Outter = styled.div`
  width : 100%;
  height: 100vh;
  display:flex;
  justify-content:center;
  background-color:${colors.$ffffff};
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  flex-direction:column;
  overflow : hidden;
  transition: 10s;
  left:0;
  z-index:5;
  @media ${props => props.theme.minSmallDesktop} {
    justify-content:unset;
    padding-top : 50px !important;
    height: auto;
    gap: 30px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    /* padding-bottom : 50px; */
  }
`;

const BgContainer = styled.div`
  width : 100%;
  height: 100%;
  display:flex;
  align-items:center;
  justify-content:right;

  @media ${props => props.theme.minSmallDesktop} {
    justify-content:center;
  }
`;

const InformationContainer = styled.div`
  width : 82%;
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content:end;
  gap: 60px;

  @media ${props => props.theme.minSmallDesktop} {
    flex-direction:column;
    width: 100%;
    height: 100%;
    align-items:unset;
    gap: 30px;
  }
`;

const LeftBox = styled.div`
  /* height: 70%; */
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  color:${colors.$000000};
  gap: 35px;
  @media ${props => props.theme.minSmallDesktop} {
    width : 100%;
    margin: 0 auto;
    gap: 30px;
    padding-left : 20px;
    padding-right : 20px;
  }

`;

const Title = styled.div`
  font-family : ${fonts.fontFamily.orbitron};
  font-size : ${fonts.orbitronFont.etc2b.size};
  font-weight : ${fonts.orbitronFont.etc2b.weight};

  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.orbitronFont.etc5b.size};
    font-weight : ${fonts.orbitronFont.etc5b.weight};
  }
`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 30px;
  height: 100%;
  @media ${props => props.theme.minSmallDesktop} {
    justify-content:normal;
  }
`;

const TopContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const KrAddress = styled.div`
  font-family : ${fonts.fontFamily.pretendard};
  font-size : ${fonts.pretendardFont.default1m.size};
  font-weight : 500;
  line-height: 36px;
  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.pretendardFont.default2m.size};
    /* font-weight : ${fonts.pretendardFont.default2m.weight}; */
    font-weight : 500;
  }

`;
const EnAddress = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : 16px;
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 24px;

  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english3r.size};
    font-weight : ${fonts.montserratFont.english3r.weight};
  }
`;


const MiddleContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english1m.size};
  font-weight : ${fonts.montserratFont.english1m.weight};
  line-height: 24px;
  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english2m.size};
    font-weight : ${fonts.montserratFont.english2m.weight};
  }

`;

const BottomContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 60px;
  @media ${props => props.theme.minSmallDesktop} {
    gap : 30px;
  }

`;

const ColumnContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 20px;
`;
const ColumnTopContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : 16px;
  font-weight : 500;
  /* font-size : ${fonts.montserratFont.english4b.size}; */
  /* font-weight : ${fonts.montserratFont.english4b.weight};  */
  line-height: 16px;

  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english3m.size};
    font-weight : ${fonts.montserratFont.english3m.weight};
  }

`;
const ColumnBottomContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : 16px;
  /* font-size : ${fonts.montserratFont.english4r.size}; */
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 24px;
  /* line-height: 16px; */

  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english3r.size};
    font-weight : ${fonts.montserratFont.english3r.weight};
  }
`;

const ContactContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const EmailContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const LastContent = styled.div`
  display:flex;
  flex-direction:column;
  gap : 5px;
`;

const FooterContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : 16px;
  /* font-size : ${fonts.montserratFont.english4r.size}; */
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 16px;
  color:${colors.$aaaaaa};

  @media ${props => props.theme.minSmallDesktop} {
  font-size : ${fonts.montserratFont.english4r.size};
  }
`;

const SNSList = styled.div`
  display:flex;
  gap:3px;

  div{
    cursor:pointer;
  }
`;


const RightBox = styled.div`
  width : 55vw;
  /* 
  height: 100%; 
  */
  border-top-left-radius : 45px;
  border-bottom-left-radius : 45px;
  overflow:hidden;
  display:flex;
  @media ${props => props.theme.minSmallDesktop} {
    width : 100%;
    border-top-left-radius : 0px;
    border-bottom-left-radius : 0px;
    min-height : 40vh;
    max-height : 40vh;
  }
`;

const MarqueeBox = styled(Marquee)`
  .rfm-child,.rfm-child img{
    object-fit: cover;
    max-width : 55vw;
    min-width : 55vw;
    overflow : hidden;
    min-height : 65vh;
    max-height : 65vh;
  }

  @media ${props => props.theme.minSmallDesktop} {
    .rfm-child,.rfm-child img{
      max-width : 100vw;
      min-width : 100vw;
      min-height : 40vh;
      max-height : 40vh;
    }
  }

`;


const RightBoxImage = styled.img`
  width : 100%;
  height : 100%;
  object-fit: cover;
`;

const FadeComponent = styled.div`
  position:absolute;
  bottom: 0;
  width : 100%;
  @media ${props => props.theme.minSmallDesktop}{
    position:relative;
    div{
      padding : 0;
    }
    padding-left : 20px;
    padding-right : 20px;
  }
`;

export default Footer