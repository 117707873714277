import React from 'react'
import { styled } from 'styled-components'

import { ReactComponent as YouTubeIcon } from "../../../assets/images/svg/ico_youtube.svg";
import { ReactComponent as InstargramIcon } from "../../../assets/images/svg/ico_instagram.svg";
import { ReactComponent as FaceBookIcon } from "../../../assets/images/svg/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/svg/ico_twitter.svg";
import { colors, fonts } from '../../../constants';
import { Link } from 'react-router-dom';

const Connect = ({ artistDetail,$ismobile, className }) => {

    const onClickNewTab = (link) => {
        window.open(link, "_blank")
    }


    return (
        <>
            {
                (artistDetail?.facebook == undefined &&
                    artistDetail?.youtube == undefined &&
                    artistDetail?.insta == undefined &&
                    artistDetail?.twitter == undefined) ||
                    (artistDetail?.facebook == "" &&
                    artistDetail?.youtube == "" &&
                    artistDetail?.insta == "" &&
                    artistDetail?.twitter == "" )
                    ?
                    "" :
                    <ConnectWrap $ismobile={$ismobile} className={className}>
                        <ConnectTitle>Artist&nbsp;Connect</ConnectTitle>
                        <ConnectList>
                            {
                                artistDetail?.facebook != "" ?
                                    <ConnectItem>
                                        <Link onClick={() => onClickNewTab(artistDetail.facebook)} >
                                            <FaceBookIcon />
                                        </Link>
                                    </ConnectItem>:""
                            }
                            {
                                artistDetail?.youtube != "" ?
                                    <ConnectItem >
                                        <Link onClick={() => onClickNewTab(artistDetail.youtube)}>
                                            <YouTubeIcon />
                                        </Link>
                                    </ConnectItem>:""
                            }
                            {
                                artistDetail?.insta != "" ?
                                    <ConnectItem >
                                        <Link onClick={() => onClickNewTab(artistDetail.insta)}>
                                            <InstargramIcon />
                                        </Link>
                                    </ConnectItem>:""
                            }

                            {
                                artistDetail?.twitter != "" ?
                                    <ConnectItem >
                                        <Link onClick={() => onClickNewTab(artistDetail.twitter)}>
                                            <TwitterIcon />
                                        </Link>
                                    </ConnectItem>:""
                            }
                        </ConnectList>
                    </ConnectWrap>
            }
        </>

    )
}

const ConnectWrap = styled.div`
    display :flex;
    flex-direction :column;
    gap : 20px;
    width : 210px;
    opacity : ${props => props.$ismobile ? "0" : "1"};
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
        flex-direction :row;
        justify-content : right;
    }
`;

const ConnectTitle = styled.div`
    color :${colors.$ffffff};
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default3m.size};
    font-weight : ${fonts.pretendardFont.default3m.weight};
    line-height: 18px;
    @media ${props => props.theme.minSmallDesktop} {
        display: none;
    }
`;

const ConnectList = styled.div`
    display :flex;
    flex-direction: row;
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        justify-content:right;
    }
`;

const ConnectItem = styled.div`
    
`;

export default Connect