import { color } from 'framer-motion';
import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../constants';
import { ReactComponent as BackImg } from "../assets/images/svg/ico_back.svg";
import { useNavigate } from 'react-router-dom';


const BackToList = () => {
    const navigator = useNavigate();


    return (
        <AllContainer>
            <BackToListComponent onClick={() => navigator(-1)}>
                <IconWrap>
                    <BackIcon />
                </IconWrap>
                <Text>Back to List</Text>
            </BackToListComponent>
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100%;
    display : flex;
    justify-content:center;
    align-items:center;
    padding : 160px 0;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 100px 0;
    }
`;

const BackToListComponent = styled.div`
    border-radius: 60px;
    border: 2px solid ${colors.$ffffff};
    gap: 10px;
    flex-shrink: 0;
    cursor: pointer;
    display : flex;
    align-items:center;
    justify-content:center;
    padding: 18px 40px;
    
    @media ${props => props.theme.minSmallDesktop} {
        border-radius: 60px;
        height : 40px;
        padding: 10px 20px;
    }

`;

const BackIcon = styled(BackImg)`
    height : 100%;
`;

const IconWrap = styled.div`
    padding: 0;
    display : flex;
    align-items:center;
    height : 24px;
    @media ${props => props.theme.minSmallDesktop} {
        height : 18px;
    }
`;

const Text = styled.div`
    color : ${colors.$ffffff};
    font-family :${fonts.fontFamily.pretendard};
    font-size : 24px;
    font-weight : 500;
    display : flex;
    align-items:center;
    padding: 0;
    height : 24px;
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 18px;
        height : 18px;
    }

`;



export default BackToList