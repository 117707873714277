import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';


const BasicInfoInputWrap = ({ data, onChange, appScrollEvent, documentHeight }) => {

    const [width, setWidth] = useState(0);

    const setListWidth = () => {
        const width = document.querySelector('.listWrap');
        setWidth(window.innerWidth <= 1024 ? "100%" : width.offsetWidth / 2.04 + "px");
    }

    useEffect(() => {
        setListWidth();
        window.addEventListener("resize", setListWidth);
        return (
            window.removeEventListener('resize', setListWidth)
        )
    }, [])

    return (
        <AllContainer>
            <RequireWrap>
                {data.map((item, index) => (
                    data.length - 1 > index ?
                        <InputWrap
                            onFocus={(e) => {
                                ScrollTrigger.disable()
                                e.target.focus()
                            }}
                            onBlur={(e) => {
                                ScrollTrigger.enable()
                                e.target.blur()
                            }}
                            // width={width}
                            value={item.vale}
                            placeholder={item.placeHolder}
                            onChange={(e) => onChange(e.target.value, index)}
                            key={index}
                            className={item.type}
                            required={true}
                            status = {item.status}
                        />
                        : ""
                ))}
            </RequireWrap>
            <TextAreaWrap
                onFocus={(e) => {
                    ScrollTrigger.disable()
                    e.target.focus()
                }}
                onBlur={(e) => {
                    ScrollTrigger.enable()
                    e.target.blur()
                }}
                value={data[data.length - 1].vale}
                placeholder={data[data.length - 1].placeHolder}
                onChange={(e)=>onChange(e.target.value, data.length-1)}

            />
        </AllContainer>
    )
}

const AllContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    gap : 20px;
`;


const RequireWrap = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap : 20px;
    width: 100%;
`;


const InputWrap = styled.input`
    flex : 1 1 40%;
    width: 100%;
    padding : 0px 40px 0px 30px;
    background-color : ${colors.$121212};
    font-family : ${fonts.fontFamily.pretendard};
    font-size :${fonts.pretendardFont.default4m.size};
    font-weight :${fonts.pretendardFont.default4m.weight};
    color : ${colors.$ffffff};
    width : ${props => props.width};
    height : 70px;
    border-radius : 20px;
    outline : 1px;
    border : 1px solid ${props => props.status == 0 ? colors.$E72626 : 'rgba(255, 0, 0, 0)'};
    &::placeholder{
        font-family : ${fonts.fontFamily.pretendard};
        font-size :${fonts.pretendardFont.default4m.size};
        font-weight :${fonts.pretendardFont.default4m.weight};
        color : ${colors.$aaaaaa};
    }
    &:focus {
        border : 1px solid ${colors.$ffffff};
    }
    @media ${props => props.theme.minSmallDesktop} {
        flex : 1 1 50%;
        border-radius : 15px;
    }
`;

const TextAreaWrap = styled.textarea`
    padding: 27px 30px 30px 30px;
    background-color : ${colors.$121212};
    font-family : ${fonts.fontFamily.pretendard};
    font-size :${fonts.pretendardFont.default4m.size};
    font-weight :${fonts.pretendardFont.default4m.weight};
    color : ${colors.$ffffff};
    min-width : 100%;
    min-height : 283px;
    max-height : 283px;
    border-radius : 20px;
    resize : none;
    outline : 0px;
    border : 1px solid rgba(255, 0, 0, 0);
    &::placeholder{
        font-family : ${fonts.fontFamily.pretendard};
        font-size :${fonts.pretendardFont.default4m.size};
        font-weight :${fonts.pretendardFont.default4m.weight};
        color : ${colors.$aaaaaa};
    }
    &:focus {
        border : 1px solid ${colors.$ffffff};
    }
    @media ${props => props.theme.minSmallDesktop} {
        border-radius : 15px;
    }
`;


export default BasicInfoInputWrap