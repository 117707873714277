import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import Card from './components/Card'
import Paging from './components/Paging'
import { useParams } from 'react-router-dom'
import NoList from './components/NoList'
import EmptyBox from "../../components/EmptyBox";
import axios from 'axios'

const MediaList = ({appScrollEvent}) => {

    const [mediaList, setMediaList] = useState([])

    const { page } = useParams();

    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [nowPage, setNowPage] = useState(page);
    const [totalPage, setTotalPage] = useState(5)
    const [take, setTake] = useState(window.innerWidth <=1024?10:20);
    const [loading, setLoading] = useState(true);

    const handleResize = () => {
        const listRef = document.querySelector(".mediaListRef") 
        setWidth(window.innerWidth <= 1024 ? "100%" : listRef?.offsetWidth / 4.2+"px");
    }

    const getMediaList = async () => {
        setLoading(true)
        const skip = take * (page - 1);
        const url = `${process.env.REACT_APP_GMAIL_API}/media?take=${take}&skip=${skip}`;
        const result = await axios.get(url);
        let array = result?.data?.data
        setMediaList(array)
        setTotalPage(Math.ceil(result?.data?.count / take))
        setLoading(false);
        // appScrollEvent();
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [mediaList]);


    useEffect(() => {
        setNowPage(page)
    }, [page])

    useEffect(() => {
        getMediaList();
    },[page])

    useEffect(() => {
        setTimeout(() => {
        appScrollEvent();
        },10)
    },[mediaList])

    if(loading){
        return(
            <OutContainer $widthbool={false}>

            </OutContainer>
        )
    }

    return (
        <OutContainer $widthbool={mediaList.length != 0}>
            {
                mediaList.length != 0 ?
                <>
                    <AllContainer>
                        <MediaListWrap className="mediaListRef">
                            {
                                mediaList.map((item, index) => (
                                    <MediaListItem key={index}>
                                        <Card item={item} width={width} key={index} />
                                    </MediaListItem>
                                ))
                            }
                        </MediaListWrap>
                        <Paging nowPage={nowPage} totalPage={totalPage} />
                    </AllContainer>
                    <EmptyBox />
                </>
                    :
                    <NoList />
            }
        </OutContainer>
    )
}

const OutContainer = styled.div`
  width : 100vw;
  height: ${props => props.$widthbool ? "100%" : "100vh"};
  /* height: ${props => props.$widthbool ? "200vh" : "100vh"}; */
`;

const AllContainer = styled.div`
    width :100%;
    height : 100%;
    padding : 120px 60px;
    display : flex;
    flex-direction : column;
    gap : 60px;

    @media ${props => props.theme.minSmallDesktop} {
        padding : 100px 20px;
    }
`;

const MediaListWrap = styled.ul`
    width :100%;
    height : 100%;
    gap : 15px;
    display : grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(17rem, 17rem);
    @media ${props => props.theme.minSmallDesktop} {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(60vw, 60vw);
        gap : 16px;
    }
`;

const MediaListItem = styled.li`
    width : 100%;
    height : 100%;
`;

export default MediaList