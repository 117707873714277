import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import Card from './Card';

const List = ({ artistList  }) => {

  const containerRef = useRef();

  const [cardWidth, setCardWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);

  const resizeCard = () => {
    const ref = document.querySelector(".containerRef")
    setCardWidth((window.innerWidth <= 1024 ? "100%" : "18.7em"))
    // setCardWidth((window.innerWidth <= 1024 ? "100%" : containerRef?.current?.offsetWidth/4.1+"px"))
    setCardHeight((window.innerWidth <= 1024 ? ref.offsetWidth/1+"px" : ref.offsetWidth/3.5+"px"))
  }   

  useEffect(() => {
    resizeCard()
    window.addEventListener('resize', resizeCard);

    return () => {
      window.removeEventListener('resize', resizeCard);
    };
  }, []); 


  return (
    <AllContainer ref={containerRef} className='containerRef'>
      <ListUl>
        {
          artistList.map((item, index) => (
            <CardLi width={cardWidth} key={index}>
              <Card item={item} width={cardWidth} height ={cardHeight}/>
            </CardLi>
          ))
        }
      </ListUl>
      {/* {
        artistList.map((item, index) => (
          <Card item={item} width={cardWidth} height ={cardHeight}/>
        ))
      } */}
    </AllContainer>
  )
}
const AllContainer = styled.div`
  width: 100%;
  height : max-content;
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  padding : 0 5em 0 5em;
  gap : 15px;
  @media ${props => props.theme.minSmallDesktop} {
    /* flex : 0; */
    display:block;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content:center;
    padding-left : 20px;
    padding-right : 20px;
    height: auto;

  }
`;

const ListUl = styled.ul`
  width : 75%;
  display : grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px; 
  @media ${props => props.theme.minSmallDesktop} {
    width : 100%;
    grid-template-columns: repeat(1, 1fr);
  }

`;

const CardLi = styled.li`
  flex : 1 1 30%;
  height : auto;
`;

export default List