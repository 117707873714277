import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../constants';

import { ReactComponent as ArrowIcon } from "../../assets/images/svg/ico_arrow_68.svg";
import { ReactComponent as Building } from "../../assets/images/svg/aboutImg.svg";
import { Link } from 'react-router-dom';

const About = () => {

    const make = "Make";
    const a = "a";
    const defference = "Difference";

    const topLeftContainerRef = useRef();
    const startComponentRef = useRef();

    const [starTop, setStarTop] = useState(window.innerWidth <= 1024 ? 150 : 200);

    const textRef = useRef();
    const rightTextRef = useRef();
    const lineRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const scrollEvent = () => {

        const containerHeight = topLeftContainerRef?.current?.offsetHeight - 40
        const scrollY = document.documentElement.scrollTop;

        let setTopvalue = window.innerWidth <= 1024 ? 150 : 200;

        let moveValue = scrollY + setTopvalue

        if (window.innerWidth >= 1025) {
            if (moveValue <= containerHeight) {
                setTopvalue = moveValue
            } else if (moveValue >= containerHeight) {
                setTopvalue = containerHeight;
            }
            // setStarTop(setTopvalue)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent);
        return () => {
            window.removeEventListener("scroll", scrollEvent)
        }
    }, [])

    const setTextEvent = () => {
        for (let i = 0; i < textRef?.current?.children.length; i++) {
            for (let x = 0; x < textRef?.current?.children[i].childNodes.length; x++) {
                setTimeout(() => {
                    textRef?.current?.children[i].childNodes[x]?.classList?.add("textEvent");
                }, [200 * (i + 1 + x)])
            }
        }
    }

    useEffect(() => {
        setTextEvent();
    }, [textRef])

    const rightBoxTextArray = [
        [
            "Mammoth-ENT는 ", "IT ", "업력 ", "10년차의 "
        ],
        [
            "탄탄한 ", "기술력을 ", "기반으로 ", "미래에 ", "발 ", "맞춰 "
        ],
        [
            "혁신적인 ", "로", 
            // "혁신적인 ", "로", "나아가고자 "
        ],
        [
            "나아가고자 ","노력하고 ", "있습니다. ", ""
        ]
    ]

    const visibleText = () => {
        const bottomRef = window.getComputedStyle(document.querySelectorAll(".char")[document.querySelectorAll(".char").length - 1]).opacity;
        if (bottomRef == 1) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', visibleText);
        return () => {
            window.removeEventListener("scroll", visibleText)
        }
    }, [])

    const getObserve = () => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            });
        });

        if (lineRef.current) {
            observer.observe(lineRef.current);
        }

        return () => {
            if (lineRef.current) {
                observer.unobserve(lineRef.current);
            }
        };
    }

    useEffect(() => {
        getObserve();
    }, []);

    return (
        <AllContainer className='about'>
            <HeightComponent>
                <StickyComponents>
                    <TopColumContainer>
                        <TopLeftContainer ref={topLeftContainerRef}>
                            <ScrollComponents ref={startComponentRef} startop={starTop}>
                                <StarComponent >
                                    <StartIcon src={require("../../assets/images/gif/mammoth entertainment_aboutScreen_motion.gif")} />
                                    <StartIconBg />
                                </StarComponent>
                                <BlueBg />
                            </ScrollComponents>
                        </TopLeftContainer>
                        <TopRightContainer>
                            <RightBoxTopText ref={textRef}>
                                <ColumnText>
                                    {make.split("").map((item, index) => (
                                        <ColumnSpanText second={index} key={index}>{item}</ColumnSpanText>
                                    ))}
                                    {a.split("").map((item, index) => (
                                        <ColumnSpanText second={index + make.length} key={index}>&nbsp;{item}</ColumnSpanText>
                                    ))}
                                </ColumnText>
                                <ColumnText>
                                    {defference.split("").map((item, index) => (
                                        <ColumnSpanText second={index + make.length + a.length} key={index}>{item}</ColumnSpanText>
                                    ))}
                                </ColumnText>
                            </RightBoxTopText>
                            <RightBoxBottomText ref={rightTextRef} className='rightText'>
                                {
                                    rightBoxTextArray.map((item, index) => (
                                        <RightBoxBottomTextColumnComponent key={index} >
                                            {
                                                item.map((item2, index2) => (
                                                    <RightBoxBottomTextColumn key={index2} className='changeColorWord'>
                                                        {item2.split("").map((item3, index3) => (
                                                            <TextComponents key={index3}>
                                                                {
                                                                index == 2 && index2 == 1 ?
                                                                    <MoveTextComponent className='textComponent'>
                                                                        <TextComponent className='moveTopText'>
                                                                            <FixedTextTop $isvisible={!isVisible}>
                                                                                <RightBoxBottomTextColumnSpan className='char'>글</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>로</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>벌</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>&nbsp;</RightBoxBottomTextColumnSpan>
                                                                            </FixedTextTop>
                                                                            <MoveTextTop $isvisible={isVisible}>
                                                                                <RightBoxBottomTextColumnSpan className='char'>글</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>로</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>벌</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>&nbsp;</RightBoxBottomTextColumnSpan>
                                                                            </MoveTextTop>
                                                                        </TextComponent>
                                                                        <TextComponent className='moveBottomText'>
                                                                            <FixedTextBottom $isvisible={!isVisible}>
                                                                                <RightBoxBottomTextColumnSpan className='char'>엔</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>터</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>테</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>인</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>먼</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>트</RightBoxBottomTextColumnSpan>
                                                                            </FixedTextBottom>
                                                                            <MoveTextBottom $isvisible={isVisible}>
                                                                                <RightBoxBottomTextColumnSpan className='char'>엔</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>터</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>테</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>인</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>먼</RightBoxBottomTextColumnSpan>
                                                                                <RightBoxBottomTextColumnSpan className='char'>트</RightBoxBottomTextColumnSpan>
                                                                            </MoveTextBottom>
                                                                        </TextComponent>
                                                                    </MoveTextComponent>
                                                                    : ""}
                                                                <TextComponent>
                                                                    <RightBoxBottomTextColumnSpan className='char'>{item3}</RightBoxBottomTextColumnSpan>
                                                                </TextComponent>
                                                            </TextComponents>
                                                        ))}
                                                    </RightBoxBottomTextColumn>
                                                ))
                                            }
                                        </RightBoxBottomTextColumnComponent>
                                    ))
                                }
                                {/* <ChangeWord /> */}
                            </RightBoxBottomText>
                        </TopRightContainer>
                    </TopColumContainer>
                </StickyComponents>
            </HeightComponent>
            <BottomColumContainer>
                <BoxContainer>
                    <BottomLeftBox>
                        <BottomLeftBoxTop>
                            <BottomLeftBoxCoulmnTop className='leftTopText'>글로벌</BottomLeftBoxCoulmnTop>
                            <BottomLeftBoxCoulmnBottom className='leftBottomText'>엔터테인먼트</BottomLeftBoxCoulmnBottom>
                        </BottomLeftBoxTop>
                        <BottomLeftBoxBottom>
                            젊고 유능한 인재들이 함께하는 Mammoth-ENT 는 새롭고 재미있는 문화를 지향하며,<BottomLeftBoxBottomWhiteText>&nbsp;동종업계 최고의 홍보 및 마케팅을 통해 소속된 모든 아티스트들의 능력을 빈틈없이 발산</BottomLeftBoxBottomWhiteText>할 수 있도록 돕습니다.
                        </BottomLeftBoxBottom>
                    </BottomLeftBox>
                    <BottomRightBox>
                        <Building width={"100%"} height={"100%"} />
                    </BottomRightBox>
                </BoxContainer>
            </BottomColumContainer>
            <AuditionContainer>
                <LineComponent ref={lineRef} className={isActive ? "isActive" : ""} />
                <MotionComponent>
                    <Link to={"/audition"}>
                        Audition
                        <div>
                            <ArrowIconComponent width={"6vw"} />
                        </div>
                    </Link>
                </MotionComponent>
            </AuditionContainer>
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100vw;
    height : 100%;
    position : relative;
    @media ${props => props.theme.minSmallDesktop} {
        display:flex;
        flex-direction:column;
        gap : 75px;
    }
`;

const HeightComponent = styled.section`
    height: 200vh;
    @media ${props => props.theme.minSmallDesktop} {
        height: auto;
    }
`;

const StickyComponents = styled.div`
    position : sticky;
    position : -webkit-sticky;
    top : 0;
    @media ${props => props.theme.minSmallDesktop} {
        position : relative;
    }

`;

const TopColumContainer = styled.div`
    width : 100vw;
    height: 100vh;
    display:flex;
    flex-direction:row;
    position : relative;
    @media ${props => props.theme.minSmallDesktop} {
        flex-direction:column;
        align-items:center;
        height: max-content;
    }
`;
const TopLeftContainer = styled.div`
    width:  50%;
    position:relative;

    @media ${props => props.theme.minSmallDesktop} {
        width:  100vw;
        height: 30rem;
    }
`;
const ScrollComponents = styled.div`
    width: 100%;
    height : max-content;
    position:absolute;
    top : ${props => props.startop}px;
    @media ${props => props.theme.minSmallDesktop} {
        width: 100%;
        top : ${props => props.startop}px;
    }
`;


const StarComponent = styled.div`
    width: 100%;
    height : 100%;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    @media ${props => props.theme.minSmallDesktop} {
        left : -20vw;
    }
`;



const StartIcon = styled.img`
    width: 140px;
    height: 140px;
    flex-shrink: 0;
    @media ${props => props.theme.minSmallDesktop} {
        width: 60px;
        height: 60px;
    }

`;

const StartIconBg = styled.div`
    border-radius: 700px;
    width: 700px;
    height: 700px;
    position:absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(134, 34, 196, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%);
    filter: blur(50px);
    @media ${props => props.theme.minSmallDesktop} {
        width: 400px;
        height: 400px;
        border-radius: 400px;
    }
`;

const BlueBg = styled.div`
    width: 700px;
    height: 700px;
    border-radius: 700px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(151, 207, 242, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%);
    filter: blur(100px);
    position : absolute;
    left : 15vw;
    top : 0rem;
    @media ${props => props.theme.minSmallDesktop} {
        width : 300px;
        height : 300px;
        border-radius : 300px;
        filter: blur(60px);
        top : 2rem;
        left : auto;
        left : 20vw;
    }

`;


const TopRightContainer = styled.div`
    color : ${colors.$ffffff};
    width:50%;
    display:flex;  
    flex-direction:column;
    justify-content:center;
    gap : 30px;

    @media ${props => props.theme.minSmallDesktop} {
        width: 100%;
        padding : 0 20px;
        gap : 15px;
        white-space:pre-line;
        word-wrap: break-word;
    }
`;

const RightBoxTopText = styled.div`
    display:flex;
    flex-direction:column;
    /* overflow:hidden; */
    line-height: 110px;
    font-family : ${fonts.fontFamily.orbitron};
    font-size : ${fonts.orbitronFont.etc1b.size};
    font-size : 6.5vw;
    /* font-weight : ${fonts.orbitronFont.etc1b.weight}; */
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        line-height: normal;
        font-size : ${fonts.orbitronFont.etc3b.size};
        /* font-size : 4vw; */
        gap : 0px;
    }
    @media ${props => props.theme.smallTablet} {
        /* font-size : 4.5vw; */
    }
    @media ${props => props.theme.mobile} {
        /* font-size : 10vw; */
    }
    `;

const ColumnText = styled.span`
    display:flex;
    
    `
const ColumnSpanText = styled.span`
    display:flex;
    transition: all 500ms ease-in;
    opacity: 0;
    transform: translateY(20px);
    &.textEvent{
        opacity: 1;
        transform: translateY(0px);
    }
`

const RightBoxBottomText = styled.div`
    font-family : ${fonts.fontFamily.pretendard};
    font-size : 2vw;
    /* font-size : 40px; */
    font-weight : 400;
    /* display : flex; */
    /* flex-direction : column; */
    gap : 6px;
    width : 80%;
    white-space:pre-line;
    word-wrap: break-word;
    @media ${props => props.theme.minSmallDesktop} {
        line-height: normal;
        /* font-size : 3vw; */
        font-size : 18px;
        gap : 0px;
        width : 100%;
    }
    @media ${props => props.theme.smallTablet} {
        /* font-size : 3vw; */
    }
    @media ${props => props.theme.mobile} {
        font-size : 15px;
    }

    `;

const RightBoxBottomTextColumnComponent = styled.div`
    /* display : flex;
    flex-direction : row; */
    gap : 10px;
    display : inline-block;
    @media ${props => props.theme.mobile} {
        gap : 5px;
        margin-bottom : 3px;
    }
    
    `;

const RightBoxBottomTextColumn = styled.div`
    color:${colors.$ffffff};
    display : inline-block;
    margin-right : 10px;
    @media ${props => props.theme.mobile} {
        margin-right : 5px;
    }
    /* display:flex; */
`;

const RightBoxBottomTextColumnSpan = styled.div`
    display : inline-block;
`;
const TextComponents = styled.span`
`;
const MoveTextComponent = styled.span`
    span {
        transform : translate(0px, 0px);
    }
    /* width: 100%; */
    display:inline-block;
`;

const TextComponent = styled.span`
    display:inline-block;
`;

const FixedTextTop = styled.span`
    display:flex;
    visibility : ${props => props.$isvisible ? "visible" : "hidden"};
`;

const FixedTextBottom = styled.span`
    display:flex;
    visibility : ${props => props.$isvisible ? "visible" : "hidden"};
`;

const MoveTextTop = styled.span`
    position:absolute;
    visibility : ${props => props.$isvisible ? "visible" : "hidden"};
    display:flex;
    top: 0;
`;

const MoveTextBottom = styled.span`
    position:absolute;
    visibility : ${props => props.$isvisible ? "visible" : "hidden"};
    display:flex;
    top: 0;
`;

const BottomColumContainer = styled.div`
    width : 100vw;
    height: 100vh;
    display:flex;
    justify-content:right;
    align-items:center;
    @media ${props => props.theme.minSmallDesktop} {
            height: auto;
    }

`;

const BoxContainer = styled.div`
    width: 85%;
    height : 70%;
    display:flex;
    align-items:center;
    @media ${props => props.theme.minSmallDesktop} {
        width: 100%;
        height : 100%;
        flex-direction:column;
        gap: 40px;
    }
`;

const BottomLeftBox = styled.div`
    width: 55%;
    height : 80%;
    color:${colors.$ffffff};
    display:flex;
    flex-direction:column;
    gap : 100px;
    @media ${props => props.theme.minSmallDesktop} {
        width: 90%;
        height :auto;
        gap : 20px;
        align-items:center;
    }
`;

const BottomLeftBoxTop = styled.div`
    width: 100%;
    /* height: 8rem; */
    display:flex;
    flex-direction:column;
    gap : 6px;
    @media ${props => props.theme.minSmallDesktop} {
        gap : 0px;
        /* height: 4rem; */
    }
    @media ${props => props.theme.mobile} {
        /* height: 3rem; */
    }
`;
const BottomLeftBoxCoulmnTop = styled.div`
    width: 100%;
    /* height: 50%; */
    position:relative;
    opacity : 0;
    font-size : 40px;
    font-weight : 400;
    @media ${props => props.theme.minSmallDesktop} {
        font-size : ${fonts.orbitronFont.etc3b.size};
        font-weight : ${fonts.orbitronFont.etc3b.weight};
        line-height: normal;
        opacity : 1;

    }

`;
const BottomLeftBoxCoulmnBottom = styled.div`
    width: 100%;
    /* height: 50%; */
    position:relative;
    opacity : 0;
    font-size : 40px;
    font-weight : 400;
    @media ${props => props.theme.minSmallDesktop} {
        font-size : ${fonts.orbitronFont.etc3b.size};
        font-weight : ${fonts.orbitronFont.etc3b.weight};
        line-height: normal;
        opacity : 1;

    }
`;

const BottomLeftBoxBottom = styled.div`
    width: 55%;
    color:${colors.$aaaaaa};
    font-family :${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default1m.size};
    font-weight : ${fonts.pretendardFont.default1m.weight};
    /* font-size : 24px;
    font-weight : 500; */
    margin-left : 6rem;
    line-height: 36px;
    @media ${props => props.theme.minSmallDesktop} {
        width: 100%;
        margin-left : 0;
        line-height: normal;
        /* font-size : 3vw; */
        font-weight : 400;
        font-size : 18px;
        /* font-weight : ${fonts.pretendardFont.default4r.weight}; */
    }
    @media ${props => props.theme.mobile} {
        font-size : 15px;
    }
`;

const BottomLeftBoxBottomWhiteText = styled.span`
    color:${colors.$ffffff};
`;
const BottomRightBox = styled.div`
    width:  35%;
    height : 100%;
    img{
        width: 100%;
    }
    @media ${props => props.theme.minSmallDesktop} {
        width: 90%;
        height : auto;
    }

`;


const AuditionContainer = styled.div`
    width: 100%;
    height:50vh;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    color:${colors.$ffffff};
    position:relative;
    @media ${props => props.theme.minSmallDesktop} {
        height: 25vh;
    }
`;

const LineComponent = styled.div`
    width: 0%;
    height: 1px;
    background-color:${colors.$ffffff};
    position :absolute;
    top:0;
    &.isActive{
        animation: line 1.5s forwards;
    }
`;
const MotionComponent = styled.div`
    font-family:${fonts.fontFamily.orbitron};
    font-size : 6vw;
    /* font-size : ${fonts.orbitronFont.etc1b.size}; */
    a{  
        color:${colors.$ffffff};
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        cursor: pointer;
        gap : 10px;
    }
    div{
        transition: 0.5s;
    }
    &:hover{
        div{
            right: 5px;
            transform:translateX(2vw)translateY(-2vw);
        }
    }
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 7vw;
    }
`;

const ArrowIconComponent = styled(ArrowIcon)`
`;


export default About