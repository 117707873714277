import React, { useEffect, useState } from 'react'


import { ReactComponent as LogoWhite } from "../assets/images/svg/logo_ent_white.svg";
import { ReactComponent as LogoBlack } from "../assets/images/svg/logo_ent_black.svg";

import { ReactComponent as MobileLogo } from "../assets/images/svg/symbol_ent.svg";

const Logo = ({changeHeader}) => {

    const [width, setWidth] = useState(window.innerWidth);

    const handleResize = () => {
      setWidth(window.innerWidth);
    };
  
    useEffect(() => {
      // 윈도우의 사이즈가 변경될 때 이벤트 핸들러 추가
      window.addEventListener('resize', handleResize);
  
      // 컴포넌트가 unmount 될 때 이벤트 핸들러 제거
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); // 빈 배열을 전달하여 컴포넌트가 마운트되었을 때 한 번만 실행

  return (
    <>
        {
            width >= 0 && width <=1024 ?
            <MobileLogo />
            :
            changeHeader ?
            <LogoBlack />
            :
            <LogoWhite />
        }
    </>
  )
}

export default Logo