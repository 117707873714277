import React, { useRef, useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";
import { ReactComponent as AboutArtist } from "../../../assets/images/svg/about_artist.svg";
import { ReactComponent as AboutPoster } from "../../../assets/images/svg/about_poster.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/ico_arrow_68.svg";
import { Link } from "react-router-dom";

import about_artist_img from "../../../assets/images/png/fourth_about_1.png";
import about_poster_img from "../../../assets/images/png/fourth_about_2.png";

const FourthComponent = () => {
  return (
    <AllContainer>
      <Section>
        <TextComponent>
          <Title>Artist Composition</Title>
          <Desc>
            행사 컨셉과 니즈에 적합한 DJ, 퍼포먼스팀 등 다양한 분야의 아티스트를
            구성하여 행사에 파견합니다.
            <br /> MAMMOTH.ENT의 아티스트들이 여러분께 즐거움을 선사할 것입니다.
          </Desc>
        </TextComponent>
        {/* <AboutArtist width={"100%"}/> */}
        <ImageComponent src={about_artist_img} />
        <AuditionContainer>
          <MotionComponent>
            <Link to={"/artistList/all/1"}>
              Artist
              <div>
                <ArrowIconComponent width={"5vw"} />
              </div>
            </Link>
          </MotionComponent>
        </AuditionContainer>
      </Section>
      <Section>
        <TextComponent>
          <Title>Poster & Graphic design services</Title>
          <Desc>
            페스티벌 포토쵤영 서비스와 영상촬영 서비스!
            <br /> 페스티벌과 이벤트의 사전/사후 제작을 다각도로 지원할 수 있는
            솔루션을 제공합니다.
          </Desc>
        </TextComponent>
        {/* <AboutPoster width={"100%"}/> */}
        <ImageComponent src={about_poster_img} />
      </Section>
    </AllContainer>
  );
};

export default FourthComponent;

const AllContainer = styled.div`
  width: 100vw;
  padding: 5vw 20vw;
  display: flex;
  flex-direction: column;
  gap: 180px;
  @media ${(props) => props.theme.minSmallDesktop} {
    padding: 100px 3vw 100px 3vw;
  }
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

const TextComponent = styled.div`
  width: 100%;
  // width: 60.5vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${colors.$ffffff};
`;

const Title = styled.div`
  width: 100%;
  font-family: ${fonts.fontFamily.orbitron};
  font-size: 2vw;
  font-weight: ${fonts.orbitronFont.etc1b.weight};
  line-height: 75%;
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 70%;
    font-size: 8vw;
    line-height: 93.75%;
  }
`;

const Desc = styled.div`
  font-family: ${fonts.fontFamily.pretendard};
  font-weight: ${fonts.pretendardFont.default1m.weight};
  font-size: 1vw;
  color: ${colors.$aaaaaa};
  line-height: 150%;

  @media ${(props) => props.theme.minSmallDesktop} {
    font-size: 5vw;
    line-height: 150%;
  }
`;

const AuditionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
  color: ${colors.$ffffff};
  position: relative;
  @media ${(props) => props.theme.minSmallDesktop} {
  }
`;

const MotionComponent = styled.div`
  font-family: ${fonts.fontFamily.orbitron};
  font-size: 3vw;
  /* font-size : ${fonts.orbitronFont.etc1b.size}; */
  a {
    color: ${colors.$ffffff};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
  }
  div {
    transition: 0.5s;
  }
  &:hover {
    div {
      right: 5px;
      transform: translateX(1vw) translateY(-1vw);
    }
  }
  @media ${(props) => props.theme.minSmallDesktop} {
    font-size: 9vw;
    a {
      align-items: center;
      gap: 20px;
      div{
        svg{
          width:10vw;
        }
      }
    }
  }
`;

const ArrowIconComponent = styled(ArrowIcon)``;

const ImageComponent = styled.img`
  width:100%;
  height : 100%;
  object-fit:cover;
  border-radius: 20px;
  @media ${(props) => props.theme.minSmallDesktop} {
    height : 100vw;
  }
`;
