
const breakpoint = {
    header : `(min-width:0px) and (max-width:1024px)`,
    wideDesktop:`(max-width:1920px)`,
    smallDesktop:`(max-width:1365px)`,
    minSmallDesktop:`(max-width:1024px)`,
    wideTablet:`(max-width:1023px)`,
    smallTablet:`(max-width:839px)`,
    mobile:`(max-width:599px)`,
    minimum:`(max-width:359px)`,

}

export default breakpoint