import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';

const Category = ({ artistType, type, categoryList }) => {

  const navigate = useNavigate();

  const goPage = (url) => {
    navigate(url);
  }

  return (
    <ListWrap>
      {categoryList?.map((item, index) => (
          <ListItem $ismatch={type.toLowerCase() === item.type.toLowerCase()} key={index}
          onClick={()=>goPage(`/artistList/${item.type.toLowerCase()}/1`)}
          >
            {item.type}
          </ListItem>
      ))}
    </ListWrap>
  )
}

const ListWrap = styled.div`
  flex-direction: row;
  width: 100%;
  gap : 0.5%;
  display: flex;
  justify-content:center;
  align-items : center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar{
    display:none;
  }
  @media ${props => props.theme.minSmallDesktop} {
    gap : 10px;
    justify-content:start;
    padding : 0 20px;
    overflow-x : scroll;
    height : auto;
    align-items : unset;
  }
`;

const ListItem = styled.div`
  height : 44px;
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english1m.size};
  font-weight : ${fonts.montserratFont.english1m.weight};
  border: 2px solid ${props => props.$ismatch ? colors.$8622C4 : colors.$aaaaaa};
  padding : 0.5% 1.5%;
  border-radius : 25px;
  color:${props => props.$ismatch ? colors.$8622C4 : colors.$aaaaaa};
  cursor: pointer;
  display : flex;
  align-items:center;
  /* &:hover{
      border-color : ${colors.$8622C4};
      a{
      color:${colors.$8622C4};
    }
  } */

  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english3m.size};
    font-weight : ${fonts.montserratFont.english3m.weight};
    padding : 10px;
    border-radius : 36px;
  }
`;

export default Category