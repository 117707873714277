import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/ico_arrow_24.svg"
import { ReactComponent as FoldIcon } from "../../../assets/images/svg/obj_moreBtnHover.svg"
import { useNavigate } from 'react-router-dom';
import { returnIsCenter } from '../../../lib/common';



const Card = ({ item, width, height }) => {
    const navigator = useNavigate()

    const goDetail = (id) => {
        navigator(`/artistDetail/${id}`)
    }

    return (
        <AllContainer width={width} height={height}
            onClick={() => { goDetail(item.id) }}
        >
            <ArtistImg
                $iscenter={returnIsCenter(item?.name)}
                src={item.imageUrl}
            />
            <BottomInfo className='info'>
                <BottomInfoName>{item.name}</BottomInfoName>
                <BottomInfoType>{item.type}</BottomInfoType>
            </BottomInfo>
            <FoldComponent>
                <ArrowIcon className='arrow'
                />
                <FoldIconComponent className='foldComponent'>
                    <FoldIcon
                        className='fold'
                        width={0} height={0}
                    />
                </FoldIconComponent>
            </FoldComponent>
        </AllContainer>
    )
}

const AllContainer = styled.div`
    /* 
    width : ${props => props.width};
    */
    height : ${props => props.height};
    /* border-radius : 35px; */
    cursor: pointer;
    position:relative;
    display:flex;
    justify-content:left;
    align-items:end;
    overflow:hidden;
    outline:none;
    border-radius : 35px;
    /* transition : .7s all; */
    /* &:hover{
        .info{
            opacity:1;
        }
        .foldComponent .fold{
            width:119px;
            height:119px;
            overflow:visible;
        }
        .arrow{
            opacity:1;
            right : 38px;
            bottom : 38px;
            transition : 0.3s  ease-in;

        }
    } */
    
    @media ${props => props.theme.minSmallDesktop} {
        border-radius : 20px;
        margin-bottom : 16px;
        /* .info{
            opacity:1;
        }
        .foldComponent .fold{
            width:99px;
            height:99px;
            overflow:visible;
        }
        .arrow{
            opacity:1;
            right : 30px;
            bottom : 30px;
            transition : 0.3s  ease-in;
        }

        &:hover{
            .info{
            opacity:1;
            }
            .foldComponent .fold{
                width:99px;
                height:99px;
                overflow:visible;
            }
            .arrow{
                opacity:1;
                right : 30px;
                bottom : 30px;
                transition : 0.3s  ease-in;
            }
        } */

  }

`;

const ArtistImg = styled.div`
    width: 100%;
    height: 100%;
    position : absolute;
    top : 0;
    left: 0;
    z-index : 0;
    /* background : url(${props => props.src}) no-repeat ${props => props.$iscenter ? "center" : ""}; */
    background-image : url(${props => props.src});
    background-repeat : no-repeat;
    background-size : cover;
    background-position: top;
    border-radius : 35px;
    @media ${props => props.theme.minSmallDesktop} {
        border-radius : 20px;
    }
`;

const BottomInfo = styled.div`
    color : ${colors.$ffffff};
    z-index : 2;
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default2m.size};
    position : absolute;
    bottom: 0;
    left : 0;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 5px;
    width : 100%;
    opacity: 0;
    transition: 0.5s;
    height: 110px;
    padding : 0 0 27px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
`;
const BottomInfoName = styled.div`
    color : ${colors.$ffffff};
    font-weight : 500;
    line-height: 20px; 
    font-size : ${fonts.pretendardFont.default2m.size};
`;
const BottomInfoType = styled.div`
    color : ${colors.$ffffff};
    font-weight : ${fonts.pretendardFont.default2m.weight};
    line-height: 20px; 
`;

const FoldComponent = styled.div`
    display:flex;
    justify-content:center;
    align-items: center;
    position: relative;
    z-index:3;
    position:absolute;
    bottom: 0;
    right : 0;
    width: 100%;
    height: 100%;
    .arrow{
        position:absolute;
        right: 50px;
        bottom: 0px;
        z-index : 1;
        opacity: 0;
        transition : .3s  ease-out;
    }
    @media ${props => props.theme.minSmallDesktop} {
        .arrow{
            /* opacity:1; */
            right : 30px;
            bottom : 30px;
        }
    }

`;

const FoldIconComponent = styled.div`
    svg{
        position:absolute;
        transition : .3s ease-out;
    }

    .fold{
        right: 0px;
        bottom:0;
    }
`;

export default Card