import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from "../../../constants";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/ico_arrow_24.svg"
import { ReactComponent as FoldIcon } from "../../../assets/images/svg/obj_moreBtnHover.svg"
import { Link } from 'react-router-dom';

const Card = ({ item, width }) => {

    return (
        <AllContainer width={width}>
            <Link to={`/mediaDetail/${item.id}`}>
                <CardWrap>
                    <CardImg src={item.imageUrl} />
                </CardWrap>
                <CardDim className='dim'>
                    <CardDimTextComponent>
                        <CardDimWhiteText>{item.title}</CardDimWhiteText>
                        <CardDimGrayText>{item.date}</CardDimGrayText>
                    </CardDimTextComponent>
                </CardDim>
                <ArrowIconImg className="arrow" />
                <FoldIconImg className="fold" />
            </Link>
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100%;
    height : 100%;
    /* border-radius : 15px; */
    overflow : hidden;
    /* width : ${props => props.width}; */
    cursor: pointer;
    position : relative;
    outline : 0px;
    &:hover {
        .dim{
            opacity : 1;
        }
        .fold{
            width : 119px;
            height : 119px;
        }
        .arrow{
            opacity:1;
            right : 38px;
            bottom : 38px;
            transition : 0.3s  ease-in;
        }
    }

    @media ${props => props.theme.minSmallDesktop} {
        /* .dim{
            opacity : 1;
        } */
        .fold{
            width : 99px;
            height : 99px;
        }
        .arrow{
            opacity:1;
            right : 30px;
            bottom : 30px;
            transition : 0.8s  ease-in;
        }    
        &:hover {
            .fold{
            width : 99px;
            height : 99px;
            }
            .arrow{
                opacity:1;
                right : 30px;
                bottom : 30px;
                transition : 0.3s  ease-in;
            }  
        }

    }

`;

const CardWrap = styled.div`
    width : 100%;
    height : 100%;
`;

const CardImg = styled.div`
    width : 100%;
    height : 100%;
    /* background : url(${props => props.src}) no-repeat center;
    background-size : cover; */
    background-image : url(${props => props.src});
    background-repeat : no-repeat;
    background-size : cover;
    background-position: center center;
    border-radius : 35px;
    @media ${props => props.theme.minSmallDesktop} {
        border-radius : 20px;
    }

`;

const CardDim = styled.div`
    display : flex;
    justify-content : center;
    opacity : 0;
    position : absolute;
    background: rgba(0, 0, 0, 0.70);
    top : 0;
    left : 0;
    width : 100%;
    height : 100%;
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(4px);
    transition : .3s ease-out;
`;

const CardDimTextComponent = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items:center;
    font-family : ${fonts.fontFamily.pretendard};
`;

const CardDimWhiteText = styled.div`
    color : ${colors.$ffffff};
    font-size : ${fonts.pretendardFont.default2m.size};
    font-weight : ${fonts.pretendardFont.default4r.weight};
`;

const CardDimGrayText = styled.div`
    color : ${colors.$aaaaaa};
    font-size : ${fonts.pretendardFont.default2m.size};
    font-weight : ${fonts.pretendardFont.default4r.weight};
`;

const ArrowIconImg = styled(ArrowIcon)`
        position:absolute;
        right: 50px;
        bottom: 0px;
        z-index : 1;
        opacity: 0;
        transition : .3s  ease-out;
        @media ${props => props.theme.minSmallDesktop} {
            width : 20px;
        }
`
const FoldIconImg = styled(FoldIcon)`
    position : absolute;
    bottom :  0px;
    right : 0px;
    width : 0;
    height : 0;
    transition : .3s ease-out;
`


export default Card