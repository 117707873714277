import React from 'react'
import Marquee from "react-fast-marquee";
import { styled } from 'styled-components';


import { colors, fonts } from '../constants';


const MarqueeText = ({ text, isVisible }) => {

  return (
    <Container
      $isview={isVisible}
    >
      <MarqueeTextComponent
        speed={100}
        style={{
          width: "100%",
        }}
      >
        {text}&nbsp;
      </MarqueeTextComponent>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  visibility:${props => props.$isview ? "visible" : "hidden"};
  opacity: ${(props) => (props.$isview ? "1" : "0")};
  height : 3vh;
  width: 100%;
  justify-content:center;
  align-items:center;
  color : ${colors.$ffffff};
  font-family: ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english3b.size};
  font-weight : ${fonts.montserratFont.english3b.weight};
  
  @media ${props => props.theme.minSmallDesktop} {
    font-size : ${fonts.montserratFont.english4b.size};
    font-weight : ${fonts.montserratFont.english4b.weight};
  }

`;

const MarqueeTextComponent = styled(Marquee)`
  &::-webkit-scrollbar {
    display: none;
  }
`


export default MarqueeText