import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';

import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/ico_arrow_68.svg";
import { Link } from 'react-router-dom';

const AuditionSection = () => {
  return (
    <AllContainer>
      <LeftGradation />
      <RightGradation />
      <Container>
        <AuditionContainer>
          <TopTextContainer>
            <TopText textalign={"left"}>MAMMOTH</TopText>
            <TopText textalign={"right"}>ENTERTAINMENT</TopText>
          </TopTextContainer>
          <BottomContainer>
            <BottomText fontSize={"2vw"} fontWeight={fonts.orbitronFont.etc4m.weight} textalign={"left"} $iscursor={false}>
              {/* <BottomText fontSize={fonts.orbitronFont.etc4m.size} fontWeight={fonts.orbitronFont.etc4m.weight} textalign={"left"} $iscursor={false}> */}
              would you<br />&nbsp;&nbsp;&nbsp;&nbsp;like to join us?
            </BottomText>
            <BottomText fontSize={"6vw"} fontWeight={fonts.orbitronFont.etc1b.weight} textalign={"right"} $iscursor={true}>
              <Link to={"/audition"}>
                {/* <BottomText fontSize={fonts.orbitronFont.etc1b.size} fontWeight={fonts.orbitronFont.etc1b.weight} textalign={"right"} $iscursor={true}> */}
                Audition
                <BottomImgContainer>
                  <ArrowIcon width={"6vw"} />
                </BottomImgContainer>
              </Link>
            </BottomText>
          </BottomContainer>
        </AuditionContainer>
        <ImageContainer>
          <Logo3DIcon src={require("../../../assets/images/gif/mammoth entertainment_simbol_3d.gif")} />
        </ImageContainer>
      </Container>
    </AllContainer>
  )
}

const AllContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color:${colors.$000000};
  position:relative;
  overflow:hidden;
  display:flex;
  justify-content:center;
  align-items:center;
  @media ${props => props.theme.minSmallDesktop} {
    height: auto;
    padding: 100px 0;
  }

`;

const LeftGradation = styled.div`
  width: 35rem;
  height : 35rem;
  border-radius: 982px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(86, 34, 196, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%);
  filter: blur(50px);
  position:absolute;
  left : -15%;
  bottom: 0%;
  @media ${props => props.theme.minSmallDesktop} {
    display:none;
  }
`;

const RightGradation = styled.div`
  width: 60rem;
  height : 60rem;
  border-radius: 982px;
  /* background: red; */
  background: radial-gradient(50% 50% at 50% 50%, rgba(86, 34, 196, 0.40) 0%, rgba(0, 0, 0, 0.00) 100%);
  filter: blur(50px);
  position:absolute;
  /* top: -50%; */
  right: -30%;
  @media ${props => props.theme.minSmallDesktop} {
    display:none;
  }
`;

const Container = styled.div`
  position:relative;
  width: 77vw;
  height: 75vh;
  display:flex;
  align-items:center;
  justify-content:center;
  @media ${props => props.theme.smallDesktop} {
    height: 65vh;
  }
  @media ${props => props.theme.minSmallDesktop} {
    height: 63vh;
  }
  @media ${props => props.theme.smallTablet} {
    height: 58vh;
  }
  @media ${props => props.theme.mobile} {
    height: 40vh;
  }
  `;

const AuditionContainer = styled.div`
  font-family:${fonts.fontFamily.orbitron};
  color:${colors.$ffffff};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  justify-content:space-between;
`;

const TopTextContainer = styled.div`
  width: 100%;
`;

const TopText = styled.div`
  width: 100%;
  font-size : 6.5vw;
  /* font-size : ${fonts.orbitronFont.etc1b.size}; */
  font-weight : ${fonts.orbitronFont.etc1b.weight};
  text-align : ${props => props.textalign};
  letter-spacing: -0.28px;
`;

const BottomContainer = styled.div`
  width:100%;
`;

const BottomText = styled.div`
  width: 100%;
  font-size : ${props => props.fontSize};
  /* font-size : ${props => props.fontSize}; */
  font-weight : ${props => props.fontWeight};
  text-align : ${props => props.textalign};
  cursor: ${props => props.$iscursor ? "pointer" : 'auto'};
  display: ${props => props.$iscursor ? "flex" : 'block'};
  justify-content:end;
  align-items:center;
  letter-spacing: -0.32px;
  div{
    transition: 0.5s;
  }
  &:hover{
    div{
      right: 5px;
      transform:translateX(2vw)translateY(-2vw);
    }
  }
  a{
    text-decoration : none;
    color:${colors.$ffffff};
    cursor: ${props => props.$iscursor ? "pointer" : 'auto'};
    display: ${props => props.$iscursor ? "flex" : 'block'};
    justify-content:end;
    align-items:center;
  }
  @media ${props => props.theme.minSmallDesktop} {
    justify-content:center;
    margin-bottom: ${props => !props.$iscursor ? "15px" : '0'};
    font-size: ${props => !props.$iscursor ? "4vw" : "auto"};
  }
`;

const BottomImgContainer = styled.div`
  margin-left : 15px;
`;

const ImageContainer = styled.div`
  width: 54rem;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  @media ${props => props.theme.minSmallDesktop} {
    top: 9vh;
  }
  @media ${props => props.theme.smallTablet} {
    top: 9vh;
  }
  @media ${props => props.theme.smallTablet} {
    top: 5vh;
  }
  @media ${props => props.theme.mobile} {
    top: 2vh;
  }
`;

const Logo3DIcon = styled.img`
  width: calc(100vw / 2);
  @media ${props => props.theme.wideDesktop} {
    width: calc(63vh / 0.85);
  }
  @media ${props => props.theme.smallDesktop} {
    width: calc(63vh / 0.6);
  }
  @media ${props => props.theme.minSmallDesktop} {
    width: calc(63vh / 0.9);
  }
  @media ${props => props.theme.smallTablet} {
    width: calc(58vh / 0.8);
  }
  @media ${props => props.theme.mobile} {
    width: calc(58vh / 1.4);
  }
  @media ${props => props.theme.minimum} {
    width: calc(58vh / 1.7);
  }
`;

export default AuditionSection