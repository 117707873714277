import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';

import { ReactComponent as CheckBoxOn } from "../../../assets/images/svg/ico_checkbox_on.svg"
import { ReactComponent as CheckBoxOff } from "../../../assets/images/svg/ico_checkbox_off.svg"


const CheckBoxList = ({ list, onClick }) => {

    const [width, setWidth] = useState(0);

    const setListWidth = () => {
        const width = document.querySelector('.listWrap');
        setWidth(window.innerWidth <= 1024 ? width.offsetWidth / 2.05 : width.offsetWidth / 4.3);
    }

    useEffect(() => {
        setListWidth();
        window.addEventListener("resize", setListWidth);
        return (
            window.removeEventListener('resize', setListWidth)
        )
    }, [])

    return (
        <CheckBoxListWrap className='listWrap'>
            {list.map((item, index) => (
                <CheckBoxItem width={width} onClick={() => onClick(index)} key={index}>
                    <IconComponents>
                        {
                            item.isCheck ?
                                <CheckBoxOnIcon /> :
                                <CheckBoxOffIcon />
                        }
                    </IconComponents>
                    <CheckBoxItemLabel>{item.name}</CheckBoxItemLabel>
                </CheckBoxItem>
            ))}
        </CheckBoxListWrap>
    )
}
const CheckBoxListWrap = styled.ul`
    width : 100%;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap : 20px;
    display : grid;
    grid-template-columns: repeat(4, 1fr);
    @media ${props => props.theme.minSmallDesktop} {
        grid-template-columns: repeat(2, 1fr);
        /* display : flex;
        flex-direction: column; */
    }

`;

const CheckBoxItem = styled.li`
    gap : 10px;
    cursor: pointer;
    display: flex;
    align-items:center;
`;

const IconComponents = styled.div``;

const CheckBoxOnIcon = styled(CheckBoxOn)`
`;

const CheckBoxOffIcon = styled(CheckBoxOff)`
`;

const CheckBoxItemLabel = styled.span`
    color :${colors.$ffffff};
    font-family  :${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default4m.size};
    font-weight : ${fonts.pretendardFont.default4m.weight};
    height : ${fonts.pretendardFont.default4m.size};
`;

export default CheckBoxList