import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from "../../../constants"

import { ReactComponent as StarIcon } from "../../../assets/images/svg/obj_nolist_type01.svg"
import { ReactComponent as LogoIcon } from "../../../assets/images/svg/obj_nolist_type02.svg"


const NoList = () => {

  const containerRef = useRef();

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
    setWidth(containerRef.current.offsetWidth / (window.innerWidth <= 1024 ? 2.1 : 5));
    setHeight(containerRef.current.offsetHeight /  (window.innerWidth <= 1024 ? 3 : 2));
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <AllContainer ref={containerRef}>
      {
        isMobile ?
          <MobileBg>
            <Wrap padding={'0rem 0 0 0'}>
              <Card width={width} height={height}><StarIcon width={"50%"}/></Card>
              <Card width={width} height={height}><LogoIcon width={"50%"}/></Card>
              <Card width={width} height={height}></Card>
              <Card width={width} height={height}></Card>
            </Wrap>
            <Wrap padding={'5rem 0 0 0'}>
              <Card width={width} height={height}></Card>
              <Card width={width} height={height}><LogoIcon width={"50%"}/></Card>
              <Card width={width} height={height}><StarIcon width={"50%"}/></Card>
              <Card width={width} height={height}></Card>
            </Wrap>
          </MobileBg>
          :
          <Bg>
            <Wrap padding={'12rem 0 0 0'}>
              <Card width={width} height={height}><StarIcon /></Card>
            </Wrap>
            <Wrap padding={'5rem 0 0 0'}>
              <Card width={width} height={height}><LogoIcon /></Card>
              <Card width={width} height={height}></Card>
            </Wrap>
            <Wrap padding={'8rem 0 0 0'}>
              <Card width={width} height={height}></Card>
              <Card width={width} height={height}><StarIcon /></Card>
            </Wrap>
            <Wrap padding={'0 0 0 0'}>
              <Card width={width} height={height}><StarIcon /></Card>
              <Card width={width} height={height}><LogoIcon /></Card>
            </Wrap>
            <Wrap padding={'20rem 0 0 0'}>
              <Card width={width} height={height}></Card>
            </Wrap>
          </Bg>
      }
      <CenterComment>
        준비중이에요.&nbsp;조금만&nbsp;기다려&nbsp;주세요!<br />
        곧&nbsp;개성과&nbsp;매력&nbsp;넘치는&nbsp;Mammoth-ENT&nbsp;만의<br />
        다양한&nbsp;스타들을&nbsp;소개&nbsp;시켜&nbsp;드릴게요.<br />
      </CenterComment>
    </AllContainer>
  )
}

const AllContainer = styled.div`
  width: 100%;
  height : 100%;
  position:relative;
`;


const Bg = styled.div`
  width: 100%;
  height : 100%;
  display : flex;
  justify-content:space-around;
  overflow:hidden;
  gap : 10px;
`;

const MobileBg = styled.div`
  width: 100vw;
  height : 100vh;
  display: inline-flex;
  gap : 10px;
  padding-left : 1rem;
`;

const CenterComment = styled.div`
  width: 100%;
  height:100%;
  top:0;
  left:0;
  font-family:${fonts.fontFamily.pretendard};
  font-size : 1.5vw;
  /* font-size : ${fonts.pretendardFont.default1m.size}; */
  font-weight : 500px;
  color:${colors.$ffffff};
  position:absolute;
  display: flex;
  justify-content:center;
  align-items:center;
  line-height: 36px;
  text-align:center;

  @media ${props => props.theme.minSmallDesktop} {
    font-size : 2vw;
    line-height: 24px;
  }
  @media ${props => props.theme.smallTablet} {
    font-size : 2.5vw;
  }
  @media ${props => props.theme.mobile} {
    font-size : 4.1vw;
  }

`;

const Wrap = styled.div`
  width : auto;
  justify-content:${props => props.content};
  padding : ${props => props.padding};
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  gap : 10px;
`;


const Card = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px ;
  border-radius: 20px;
  background: ${colors.$121212};
  display: flex;
  justify-content:center;
  align-items:center;
`;

export default NoList