import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import { FadeText } from '../../../components';

const Intro = ({ mainSectionRef, isVisible }) => {
  return (
    <Container ref={mainSectionRef}>
      <VideoContainer>
        <VideoComponent autoPlay loop muted={true} playsInline width={"100%"} height={"100%"}>
          <source src={require("../../../assets/video/mammoth_ent_homepage_video.mp4")} />
        </VideoComponent>
      </VideoContainer>
      <FadeText isVisible={isVisible} topText={"MAMMOTH"} bottomText={"ENTERTAINMENT"} fontColor={colors.$ffffff}/>
    </Container>
  )
}

const Container = styled.div`
  width : 100%;
  height : 100vh;
  background-color : ${colors.$000000};
  display:flex;
  flex-direction:column;
  justify-content:end;
  position:relative;

  @media ${props => props.theme.minSmallDesktop} {
    padding-top : 100px;
    justify-content:unset;
    height : auto;
  }
`;

const VideoContainer = styled.div`
  position:absolute;
  display : flex;
  width : 100%;
  height : 100%;
  align-items:center;
  justify-content:center;
  left : 0;
  top : 0;
  @media ${props => props.theme.minSmallDesktop} {
    position:relative;
  }
`;

const VideoComponent = styled.video`
  /* height: 100vh; */
  /* width: 100vw; */
  /* z-index : -4; */
  object-fit:cover;
  @media ${props => props.theme.minSmallDesktop} {
    height: 100vw;
    width: 100vw;
    /* object-fit:fill; */
    object-fit:cover;

  }

`;

export default Intro