import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import Category from './components/Category';
import List from './components/List';
import Paging from './components/Paging';
import NoList from './components/NoList';
import { useLocation, useParams } from 'react-router-dom';
import gsap from 'gsap';
import axios from 'axios';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import EmptyBox from "../../components/EmptyBox";



const Artist = ({ appScrollEvent }) => {

  const { type, page } = useParams();

  const [totalPage, setTotalPage] = useState(4);
  const [artistList, setArtistList] = useState([]);
  const [take, setTake] = useState(9);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([])

  const getArtistList = async () => {
    // setLoading(true)
    const skip = take * (page - 1)
    let url = `${process.env.REACT_APP_GMAIL_API}/artist?take=${take}&skip=${skip}&type=${type}`
    let result = await axios.get(`${url}`);
    let array = result?.data?.data
    let category = [{ _count: { type: 0 }, type: "All" }];
    for (let i = 0; i < result?.data?.group.length; i++) {
      category.push(result?.data?.group[i])
    }
    category = category.filter((e) => e.type == "DJ" || e.type == "Cheerleader" || e.type == "All" )
    setArtistList(array)
    setTotalPage(Math.ceil(result.data.total / take));
    setCategoryList(category);
    setLoading(false)
  }

  useEffect(() => {
    getArtistList();
  }, [type, page])

  useEffect(() => {
    setTimeout(() => {
      appScrollEvent();
    }, 10)
  }, [artistList])

  // if(loading && artistList.length == 0){
  if (loading) {
    return (<OutContainer $widthbool={false}>

    </OutContainer>)
  }

  return (
    <OutContainer $widthbool={artistList.length != 0}>
      {
        artistList.length != 0 ?
          <>
            <AllContainer $widthbool={artistList.length != 0}>
              <Category type={type} categoryList={categoryList} />
              <List artistList={artistList}
              />
              <Paging type={type} nowPage={page} totalPage={totalPage} />
            </AllContainer>
            <EmptyBox />
          </>
          : <NoList />
      }
    </OutContainer>
  )
}

const OutContainer = styled.div`
  width : 100vw;
  height: ${props => props.$widthbool ? "100%" : "100vh"};
  /* padding-bottom: ${props => props.$widthbool ? "10px" : "0px"}; */
`;

const AllContainer = styled.div`
  padding-top : 13vh;
  display: flex;
  flex-direction:column;
  gap : 40px;
  width: 100%;
  /* margin-bottom: ${props => props.$widthbool ? "5rem" : "0"}; */
`;

const Wrap = styled.div`
  padding-top : 13vh;
  width: 100%;
  height : auto;
  gap : 40px;
  display: flex;
  flex-direction:column;
  position: relative;
  @media ${props => props.theme.minSmallDesktop} {
    width: 100%;
    gap : 20px;
    height : auto;
    padding-top : 60px;
  }
`;

export default Artist