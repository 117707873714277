import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import Card from './Card';
import { throttle } from 'lodash';
import { motion, useMotionValue, useSpring } from 'framer-motion';
import "./slick.css";

import { useHorizontalScroll } from './useSideScroll';
import { fonts, colors } from "../../../constants";

const Slick = ({ list }) => {

    const scrollRef = useHorizontalScroll();
    const slideWrapRef = useRef();
    const slideBoxRef = useRef();

    const [translateZ, setTranslateZ] = useState(0);

    const motionVal = useMotionValue(0)
    const slideScroll = useSpring(motionVal, {
        bounce: 0,
        damping: 25,
        velocity: 2
    });

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startPos, setStartPos] = useState(0);
    const [endPos, setEndPos] = useState(0);
    const [nowId, setNowId] = useState(13);

    let allowWheel = true;

    const handleScroll = throttle((e) => {
        if (allowWheel) {
            if (e.deltaY < 0) {
                motionVal.set(motionVal.get() - Math.abs(e.deltaY) * 0.1);
            } else if (e.deltaY > 0) {
                motionVal.set(motionVal.get() + Math.abs(e.deltaY) * 0.1);
            }
        }
    }, 0);

    const handleMouseDown = (e) => {
        setIsMouseDown(true);
        setStartPos(e.clientX);
    };

    const handleMouseMove = throttle((e) => {
        e.stopPropagation();
        setEndPos(e.clientX);
    }, 0);

    const onOverMouse = (id) => {
        setNowId(id)
    }

    useEffect(() => {
        function setSlide() {
            if (scrollRef.current && 0 < scrollRef.current.offsetHeight) {
                try {
                    // slide gap
                    const recruitSlide = document.querySelectorAll('.cards');
                    const z = Math.round(scrollRef.current.offsetHeight / 2 / Math.tan(Math.PI / recruitSlide.length));
                    setTranslateZ(z);
                    recruitSlide.forEach((el, idx) => {
                        el.style.transform = 'rotateY(' + (360 / recruitSlide.length) * idx + 'deg) translateZ(' + z + 'px)';
                    });
                } catch (error) {

                }
            }
        }
        setSlide();
        window.addEventListener("resize", setSlide);
        slideBoxRef.current.addEventListener('mousedown', handleMouseDown);
        const sectionObserver = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                window.addEventListener('wheel', handleScroll);
            } else {
                window.removeEventListener('wheel', handleScroll);
            }
        },{ threshold: 0 });
        sectionObserver.observe(slideWrapRef.current)

        return () =>{
            if(sectionObserver) sectionObserver.disconnect()
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener("resize", setSlide);

        }
    }, [list])
    // endPos 바뀔 때
    useEffect(() => {
        let timer;
        let mousePos = startPos - endPos;
        clearTimeout(timer);
        motionVal.set(motionVal.get() + mousePos * 0.05);

        timer = setTimeout(() => {
            const event = new MouseEvent("mouseup", {
                view: window,
                bubbles: true,
                cancelable: true,
            });
            window.dispatchEvent(event);
        }, 200)
    }, [endPos]);

    // mouseDown 바뀔 때
    useEffect(() => {
        const handleMouseUp = (e) => {
            setIsMouseDown(false);
            slideBoxRef.current.removeEventListener('mousemove', handleMouseMove);
        }
        if (isMouseDown) {
            // 드래그 위치 계산
            slideBoxRef.current.addEventListener('mousemove', handleMouseMove);

            // 드래그 끝
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        }
    }, [isMouseDown]);

    return (
        <OutContainer>
            <AllContainer>
                <TextContainer>
                    <Title>Our Artist</Title>
                    <Content>
                        MAMMOTH&nbsp;Entertainment의&nbsp;아티스트들은<br />
                        뛰어난&nbsp;기술력과&nbsp;홍보마케팅을&nbsp;통해&nbsp;최고의&nbsp;능력을&nbsp;발휘&nbsp;할&nbsp;수&nbsp;있도록&nbsp;성장하고&nbsp;있습니다.
                    </Content>
                </TextContainer>
                <SliderContainer>
                    <BgContainer />
                    <SlickContainer ref={slideBoxRef}>
                        <Container ref={scrollRef} style={{ perspective: translateZ * 2 + "px" }}>
                            <motion.ul
                                className='ul'
                                ref={slideWrapRef}
                                style={{
                                    z: translateZ + "px",
                                    rotateY: slideScroll,
                                }}
                            >
                                {
                                    list.map((item, index) => (
                                        <Li id={item.index} z={689} className='cards' key={index}>
                                            <Card item={item} onOverMouse={onOverMouse} nowId={nowId} />
                                        </Li>
                                    ))
                                }
                            </motion.ul>
                        </Container>
                    </SlickContainer>
                </SliderContainer>
            </AllContainer>
        </OutContainer>
    )
}

const SliderContainer = styled.div`
    position:relative;
`
const OutContainer = styled.div`
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    background-color:${colors.$000000};
    overflow:hidden;
    padding-top : 100px;
`;

const AllContainer = styled.div`
    width:100%;
    height: 100vh;
    position:relative;
`;
const SlickContainer = styled.div`
    width:100%;
    height: auto;
`;

const TextContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap : 20px;
    justify-content:center;
    align-items:center;
    color:${colors.$ffffff};
    padding-top: 100px;
    margin-bottom :4%;
    div{
        width: 40rem;
        text-align:left;
        line-height: 27px;
    }
`;

const Title = styled.div`
    font-family:${fonts.fontFamily.orbitron};
    font-size:${fonts.orbitronFont.etc2b.size};
    font-weight:${fonts.orbitronFont.etc2b.weight};

`;

const Content = styled.div`
    font-family:${fonts.fontFamily.pretendard};
    font-size:${fonts.pretendardFont.default3r.size};
    font-weight:400;
    /* font-weight:${fonts.pretendardFont.default3r.weight}; */
    color:${colors.$aaaaaa};
`;

const Container = styled.div`
    perspective:2000px;
    cursor: ew-resize;
    display:flex;
    justify-content:center;
    position:relative;
    transform:rotate(6deg);
    width:100%;
`;

const Li = styled.li`
    height:100%;
    padding :1vw;
    position:absolute;
    -webkit-user-select:none;
    width:100%;
    transform:rotateY(${props => props.id * 30}deg) translateZ(${props => props.z}px);
`;

const BgContainer = styled.div`
    /* border-radius: 1200px; */
    background: radial-gradient(50% 50% at 50% 50%, rgba(134, 34, 196, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    position:absolute;
    width : 40vw;
    height : 40vw;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    filter: blur(50px);

`;



export default Slick