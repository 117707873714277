import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import Slider from "react-slick";

import './slick.css';

const ArtistPhoto = ({artistDetailImage,iscenter}) => {

    const photoRef = useRef();
    const [isActive, setIsActive] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    const getObserve = () => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            });
        });

        if (photoRef.current) {
            observer.observe(photoRef.current);
        }

        return () => {
            if (photoRef.current) {
                observer.unobserve(photoRef.current);
            }
        };
    }

    useEffect(() => {
        getObserve();
    }, []);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 1024)
        window.addEventListener('resize', () => setIsMobile(window.innerWidth <= 1024));
        window.addEventListener('load', () => setIsMobile(window.innerWidth <= 1024));
        return () => {
            window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 1024));
            window.removeEventListener('load', () => setIsMobile(window.innerWidth <= 1024));
        };
    }, []);

    return (
        <AllContainer>
            <BgContainer>
                <Text>Artist Photo</Text>
                {
                    isMobile ?
                        <MobilePhotoList>
                            <MobilePhotoSlider {...settings}>
                                {
                                    artistDetailImage.map((item, index) => (
                                        <PhotoItem delay={0} className={"isActive"} 
                                        src={item.imageUrl}
                                        key={index}
                                        $iscenter={iscenter}
                                        />
                                    ))
                                }
                            </MobilePhotoSlider>
                        </MobilePhotoList>
                        :
                        <PhotoList ref={photoRef}>
                            {
                                artistDetailImage.map((item, index) => (
                                    <PhotoItem delay={(index+1) * 0.4} className={isActive ? "isActive" : ""} 
                                    src={item.imageUrl}
                                    key={index}
                                    $iscenter={iscenter}
                                    />
                                ))
                            }
                        </PhotoList>
                }
            </BgContainer>
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100%;
    height : 100vh;
    padding : 0 60px;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 0 20px;
        height : auto;
    }

`;

const BgContainer = styled.div`
    width : 100%;
    height: 100%;
    display: flex;
    padding: 160px 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-radius: 40px;
    background: ${colors.$121212};
    @media ${props => props.theme.minSmallDesktop} {
        padding : 40px 20px;
        border-radius: 15px;
    }
`;

const Text = styled.div`
    color : ${colors.$ffffff};
    font-family : ${fonts.fontFamily.pretendard};
    font-weight : 500;
    font-size : 50px;

    @media ${props => props.theme.minSmallDesktop} {
        font-size : 10vw;
    }
`;

const PhotoList = styled.div`
    width : 100%;
    height : 100%;
    display : flex;
    gap : 20px;
    border-radius : 25px;
`;

const MobilePhotoList = styled.div`
    width : 100%;
    .slick-dots {
    .slick-active {
      button::before {
        color: #c1c1c1;
      }
    }
    button::before {
      color: #e9e9e9;
    }
  }

  @media ${props => props.theme.minSmallDesktop} {
    flex : 2
   }
`;

const MobilePhotoSlider = styled(Slider)`
`;

const PhotoItem = styled.div`
    width : 25%;
    border-radius : 25px;
    opacity : 0;
    transition : ease-in 0.1s;
    /* background : url(${props => props.src}) no-repeat  ${props => props.$iscenter ? "center" : ""};
    background-repeat : no-repeat;
    background-size : cover; */
    background-image : url(${props => props.src});
    /* background-image : url("https://d2eskwibiybmb4.cloudfront.net/ent-home/mainTop/asd.png"); */
    background-repeat : no-repeat;
    background-size : cover;
    background-position: top;
    /* background-position: center center; */
    
    &.isActive{
        opacity:1;
        transition : ease-in ${props => props.delay}s;
    }
    @media ${props => props.theme.minSmallDesktop} {
        height : 100vw;
        background : url(${props => props.src}) no-repeat ${props => props.$iscenter ? "center" : ""};
        background-size : cover;
        background-position: top;
        border-radius : 15px;

    }
`;

export default ArtistPhoto