import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import { useNavigate } from 'react-router';

const Card = ({ item, onOverMouse, nowId }) => {

  const navigator = useNavigate();

  const goDetail = () => {
    if(item.artistData?.name){
      navigator(`/artistDetail/${item.artistData?.id}`)
    }
  }

  return (
    <Container
      onMouseOver={() => onOverMouse(item.id)}
      onMouseLeave={() => onOverMouse(888888)}
      onClick={() => {
        goDetail()
      }}
    >
      <ImageComponent>
        <Img
          src={item?.imageUrl}
          alt={item.name}
          />
      </ImageComponent>
      <DimComponent now={nowId} id={item.id}>
        <DimComponentText>{item.title}</DimComponentText>
      </DimComponent>
    </Container>
  )
}

const Container = styled.div`
    width:100%;
    height:100%;
    position:relative;
    transform:scaleX(-1);
    /* background-color: ${colors.$ffffff}; */
    border-radius : 15px;
    display:flex;
`;

const ImageComponent = styled.div`
  width : 100%;
  height : 100%;
  position:absolute;
  /* border-radius : 15px; */
  overflow:hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const Img = styled.div`
  border-radius : 15px;
  width : 100%;
  height : 100%;
  /* position:absolute;
  top:0;
  left:0; */
  /*   
  background-image : url(${props => props.src});
  background-size : cover; 
  background-repeat : no-repeat; */
  background-image : url(${props => props.src});
  background-repeat : no-repeat;
  background-size : cover;
  background-position: bottom 60% center;
  /* object-fit:cover; */
 
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
 /* user-drag: none; */
`;

const DimComponent = styled.div`
  visibility: ${props => props.now === props.id ? "visible" : "hidden"};
  position:absolute;
  border-radius : 15px;
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: rgba(0, 0, 0, 0.70);

`;


const DimComponentText = styled.div`
  width : 40%;
  text-align : center;
  display:flex;
  align-items:end;
  justify-content:center;
  color:${colors.$ffffff};
  font-family:${fonts.fontFamily.orbitron};
  font-size:${fonts.orbitronFont.etc2b.size};
  font-weight:${fonts.orbitronFont.etc2b.weight};
  transform:rotate(-6deg);
  @media ${props => props.theme.minSmallDesktop} {
    font-size:${fonts.orbitronFont.etc5b.size};
  }
`;

export default Card