import React from 'react'
import { styled } from 'styled-components'

const EmptyBox = () => {
  return (
    <Empty></Empty>
  )
}

export default EmptyBox

const Empty = styled.div`
    height : 40px;
`;