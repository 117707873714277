import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as FirstSectionImg } from "../../assets/images/svg/aboutV2FirstSection.svg";
import first_about_img from "../../assets/images/png/first_about.png";
import first_about_img_mo from "../../assets/images/png/first_about_mo.png";
 
import { colors, fonts } from "../../constants";

const AbountV2 = () => {
  const firstSectionRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [bottomIsVisible, setBottomIsVisible] = useState(false);
  const secondSectionText = [
    [
      "MAMMOTH",
      ".ENT는",
      "DJ",
      "파트에서의",
      "기획부터",
      "장비",
      "렌탈,",
      "스테이지,",
      "디자인,",
      "DJ",
      "구성까지",
      "아우르는",
      "엔터테이먼트입니다.",
    ],
    [
      "기획부터",
      "장비",
      "렌탈,",
      "스테이지",
      "프로덕션,",
      "디자인,",
      "아티스트",
      "구성을",
      "중심으로,",
      "페스티벌",
      "문화의",
      "새로운",
      "패러다임을",
      "제시하고",
      "있습니다.\n",
      "MAMMOTH.ENT는",
      "앞으로도",
      "끊임없는",
      "투자와",
      "다양한",
      "컨텐츠",
      "개발을",
      "통해",
      "즐겁고",
      "건전한",
      "페스티벌",
      "문화를",
      "보급하며,",
      "모두와",
      "함께",
      "성장해나가겠습니다.",
    ],
  ];

  const getObserve = () => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setBottomIsVisible(false);
        }
      });
    });

    if (firstSectionRef.current) {
      observer.observe(firstSectionRef.current);
    }

    return () => {
      if (firstSectionRef.current) {
        observer.unobserve(firstSectionRef.current);
      }
    };
  };

  useEffect(() => {
    getObserve();
  }, []);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setBottomIsVisible(true);
      }, [1000]);
    }
  }, [isVisible]);

  return (
    <AllContainer>
        <FirstSection ref={firstSectionRef}>
          <PurpleColorBg />
          <FirstSectionTextComponent>
            <Text textalign={"left"} $isview={isVisible} second={3}>
              MAMMOTH.
            </Text>
            <Text textalign={"right"} $isview={bottomIsVisible} second={5}>
              ENTERTAINMENT
            </Text>
          </FirstSectionTextComponent>
          <div />
          <FirstSectionImage src={window.innerWidth>1024? first_about_img : first_about_img_mo}/>
          {/* <FirstSectionImgComponent /> */}
        </FirstSection>
        <HeightComponent>
          <StickyComponents>
            <SecondSection>
              <TextComponent className="rightText">
                {secondSectionText.map((item, index) => (
                  <SecondTextWords key={index} className="changeColorWord">
                    {item.map((item, index) => (
                      <SecondTextWord key={index} className="char">
                        {item}{" "}
                      </SecondTextWord>
                    ))}
                    {index == 0 ? (
                      <div>
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                  </SecondTextWords>
                ))}
              </TextComponent>
            </SecondSection>
          </StickyComponents>
        </HeightComponent>
    </AllContainer>
  );
};

export default AbountV2;

const AllContainer = styled.div`
  width: 100vw;
  height:auto;
  position: relative;
  display:flex;
  flex-direction:column;
  @media ${(props) => props.theme.minSmallDesktop} {
    overflow:hidden;
  }

`;

const FirstSection = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow:hidden;
`;

const FirstSectionTextComponent = styled.div`
  font-family: ${fonts.fontFamily.orbitron};
  font-size: 5.6vw;
  // line-height: 130px;
  font-weight: ${fonts.orbitronFont.etc1b.weight};
  color: ${colors.$ffffff};
  width: 60.5vw;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  top : 18vh;
  @media ${(props) => props.theme.minSmallDesktop} {
      font-size: 8vw;
      left:2.5vw;
      transform:translateX(2.5vw);
      top : 16%;
  }
`;


const FirstSectionImgComponent = styled(FirstSectionImg)`
  width:100%;
  height: 67vh;
  @media ${(props) => props.theme.minSmallDesktop} {
    width :auto;
    height: 90vh;
  }
`;

const FirstSectionImage = styled.img`
  width : 100vw;
  height : 65vh;
  @media ${(props) => props.theme.minSmallDesktop} {
    height : 65vh;
    object-fit:cover;
  }
`;


const Text = styled.div`
  text-align: ${(props) => props.textalign};
  letter-spacing: -0.28px;
  animation: ${(props) => (props.$isview ? "fadein" : "fadeout")} 3s;
  -moz-animation: ${(props) => (props.$isview ? "fadein" : "fadeout")} 3s;
  -webkit-animation: ${(props) => (props.$isview ? "fadein" : "fadeout")} 3s;
  -o-animation: ${(props) => (props.$isview ? "fadein" : "fadeout")} 3s;
  opacity: ${(props) => (props.$isview ? "1" : "0")};
  visibility: ${(props) => (props.$isview ? "visible" : "hidden")};
  @media ${(props) => props.theme.minSmallDesktop} {
    text-align: left;
  }
`;

const PurpleColorBg = styled.div`
  width: 480px;
  height: 480px;
  position: absolute;
  top: -107px;
  left: 110px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(134, 34, 196, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(50px);
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 250px;
    height: 250px;
    border-radius: 400px;
    top: 5vh;
    left:0px;
  }
`;

const SecondSection = styled.div`
  width: 100%;
  height: 100vh;
  font-family: ${fonts.fontFamily.pretendard};
  font-size: 1.5vw;
  font-weight: 600;
  gap: 6px;
  white-space: pre-line;
  word-wrap: break-word;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 150%;
  @media ${(props) => props.theme.minSmallDesktop} {
    font-size: 5vw;
    height: auto;
    line-height: 160%;
    padding : 178px 0;
  }
`;
const HeightComponent = styled.section`
  height: 150vh;
  @media ${(props) => props.theme.minSmallDesktop} {
    height: 100%;
  }
`;
const StickyComponents = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  @media ${(props) => props.theme.minSmallDesktop} {
    position: relative;
  }
`;

const TextComponent = styled.div`
  width: 60.5vw;
  margin: 0 auto;
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 90%;
  }

`;

const SecondTextWords = styled.div``;
const SecondTextWord = styled.span`
  color: white;
`;

const ThirdSection = styled.div`
  width: 100%;
  background-color: red;
  height: 100vh;
`;

const FourthSection = styled.div`
  width: 100%;
  background-color: blue;
  height: 100vh;
`;
