import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../constants';
import { ReactComponent as OpenIcon } from "../assets/images/svg/btn_openSidemenu.svg"
import { ReactComponent as OpenIcon_black } from "../assets/images/svg/btn_openSidemenu_black.svg"
import { ReactComponent as CloseIcon } from "../assets/images/svg/btn_closeSidemenu.svg"
import { ReactComponent as CloseIcon_white } from "../assets/images/svg/btn_closeSidemenu_white.svg"
import { Link } from 'react-router-dom';

const NavBar = ({ changeHeader, onClickMenuBox, isOpen, mobileNav }) => {

  const navItem = [
    {
      link: "/about",
      name: "ABOUT"
    },
    {
      link: "/artistList/all/1",
      name: "ARTIST"
    },
    {
      link: "/mediaList/1",
      name: "MEDIA"
    },
    {
      link: "/audition",
      name: "AUDITION"
    },
  ]

  return (
    <NavBarContainer>
      <Container
        onClick={onClickMenuBox}
        open={isOpen}
      >
        <MenuList
          className='headerUl'
          open={isOpen}
        >
          {
            navItem.map((item, index) => (
              <MenuItem key={index}>
                <Link to={item.link}>
                  {
                    item.name.split("").map((item2, index2) => (
                      <MenuItemP className='word' key={index2}>
                        <span >{item2}</span>
                        <span >{item2}</span>
                      </MenuItemP>
                    ))
                  }
                </Link>
              </MenuItem>
            ))
          }
        </MenuList>
        <IconComponent>
          {
            isOpen ? <CloseIcon /> : mobileNav ? <CloseIcon_white /> : window.innerWidth >= 1024 && changeHeader && !isOpen ? <OpenIcon_black /> : changeHeader ? <OpenIcon_black />:<OpenIcon />
          }
        </IconComponent>
      </Container>
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div`
`;

const Container = styled.nav`
  max-width : ${props => props.open ? '1000px': "0px"};
  min-width : 68px;
  min-height : 68px;
  position:relative;
  display : flex;
  align-items:center;
  justify-content : right;
  /* background-color : ${colors.$ffffff}; */
  background-color : ${props => props.open ? colors.$ffffff: ""};
  z-index : 99;
  padding: ${props => props.open ? "29px 68px" : "19px"}; 
  padding-left: ${props => props.open ? "55px" : "0"};
  border-radius : 44px;
  overflow:hidden;
  transition:  max-width .4s ease-in, padding .6s ease-in-out;
  -webkit-transition:  max-width .4s ease-in, padding .6s ease-in-out;

  @media ${props => props.theme.minSmallDesktop} {
    min-width : 40px;
    min-height : 40px;
  }
`;

const MenuList = styled.ul`
  display: ${props => props.open ? "flex" : "none"}; 
  margin-right : 30px;
  align-items:center;
`;

const MenuItem = styled.li`
  width: 150px;
  height : ${fonts.orbitronFont.etc6b.size};
  cursor: pointer;
  color:${colors.$000000};
  font-family:${fonts.fontFamily.orbitron};
  font-size:${fonts.orbitronFont.etc6b.size};
  font-weight:${fonts.orbitronFont.etc6b.weight};
  font-style : normal;
  a {
    color :${colors.$000000};
    display :flex;
    overflow : hidden;
  }
`;
const MenuItemP = styled.p`
  position:relative;
  height: ${fonts.orbitronFont.etc6b.size};
  display:flex;
  flex-direction:column;

`;

const IconComponent = styled.div`
  display : flex;
  align-items:center;
  justify-content: center;
  position:absolute;
  right : 0;
  width : 68px;
  height : 68px;
  @media ${props => props.theme.minSmallDesktop} {
        width : 40px;
        height : 40px;
  }
`;


export default NavBar