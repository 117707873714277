import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../constants';
import DetailTop from './components/DetailTop';
import { BackToList } from '../../components';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const MediaDetail = ({appScrollEvent}) => {

    const { idx } = useParams();

    const [detail, setDetail] = useState()
    const [html, setHtml] = useState()

    const getMediaDetail = async () => {
        const url = `${process.env.REACT_APP_GMAIL_API}/media/detail/${idx}`;
        const result = await axios.get(url);
        setDetail(result.data.data);
        setHtml(result.data.html);
    }

    useEffect(() => {
        if(detail != undefined){
            setTimeout(() => {
              appScrollEvent();
            },100)
        }
      },[detail])

    const getDate = (prevDate) => {
        const date = new Date(prevDate);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}.${month}.${day}`;
        return formattedDate
    }

    useEffect(() => {
        getMediaDetail()
    }, [])

    return (
        <AllContainer>
            <MediaDetailWrap>
                <DetailTop leftBoxText={getDate(detail?.createdAt)} rightBoxText={detail?.date} />
                <MediaDetailContent>
                    <Title>{detail?.title}</Title>
                    <Line />
                    <MediaTextArea dangerouslySetInnerHTML={{ __html: html }} />
                </MediaDetailContent>
            </MediaDetailWrap>
            <BackToList />
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100vw;
    height : auto;
    padding : 120px 360px 0 360px;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 60px 20px 0 20px;
    }
`;

const MediaDetailWrap = styled.div`
    display : flex;
    flex-direction : column;
    gap : 20px;
    width : 100%;
    @media ${props => props.theme.minSmallDesktop} {
        gap : 10px;
    }

`;

const MediaDetailContent = styled.div`
    padding : 40px;    
    background-color :${colors.$121212};
    color : ${colors.$ffffff};
    border-radius : 40px;
    display : flex;
    flex-direction : column;
    gap : 40px;
    justify-content:center;
    width : 100%;
    font-family : ${fonts.fontFamily.pretendard};
    @media ${props => props.theme.minSmallDesktop} {
        gap : 20px;
        padding : 20px;    
        border-radius : 15px;
    }

`;

const Title = styled.div`
    white-space:pre-line;
    word-wrap: break-word;
    font-size : ${fonts.pretendardFont.default1m.size};
    font-weight : ${fonts.pretendardFont.default1m.weight};
`;

const Line = styled.div`
    height : 1px;
    background-color : ${colors.$444444};
`;

const MediaTextArea = styled.div`
        width : 100%;
    *{
        white-space:pre-line !important;
        word-wrap: break-word !important;
        width : 100% !important;
        line-height : 1.3 !important;
        background-color : ${colors.$121212} !important;
        color : ${colors.$ffffff} !important;
        font-family : ${fonts.fontFamily.pretendard} !important;
        font-size : ${fonts.pretendardFont.default4m.size} !important;
        font-weight :${fonts.pretendardFont.default4m.weight} !important;
    }
    img{
            width : 100% !important;
            height : auto !important;
            object-fit : contain;
            margin-top : 20px !important;
            margin-bottom : 20px !important;
    }

    @media ${props => props.theme.minSmallDesktop} {
        img{
            width : 100% !important;
            height : auto !important;
            object-fit : contain;
        }
    }
    
`;

export default MediaDetail