import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { Footer, Header } from "./components";
import "./App.css";
import MainPage from "./pages/Main/MainPage";
import { useEffect, useRef, useState } from "react";
import { colors } from "./constants";
import ArtistList from "./pages/ArtistList/ArtistList";

import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import About from "./pages/About/About";
import ArtistDetail from "./pages/ArtistDetail/ArtistDetail";
import MediaList from "./pages/MediaList/MediaList";
import MediaDetail from "./pages/MediaDetail/MediaDetail";
import Audition from "./pages/Audition/Audition";
import SplitType from "split-type";
import AbountV2 from "./pages/About/AbountV2";
import ThirdComponent from "./pages/About/components/ThirdComponent";
import FourthComponent from "./pages/About/components/FourthComponent";

gsap.registerPlugin(ScrollTrigger);

function App() {
  const mainSectionRef = useRef();
  const informationSectionRef = useRef();
  const headerRef = useRef();
  const containerRef = useRef();
  let beforeContainerRef = useRef(containerRef);
  const aboutSection3Ref = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const [changeHeader, setChangeHeader] = useState(false);
  const [pageName, setPageName] = useState();
  const [documentHeight, setDocumentHeight] = useState(0);

  const getObserve = () => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    });

    if (mainSectionRef.current) {
      observer.observe(mainSectionRef.current);
    }

    return () => {
      if (mainSectionRef.current) {
        observer.unobserve(mainSectionRef.current);
      }
    };
  };

  useEffect(() => {
    if (pageName == "/") {
      getObserve();
    }
  }, [pageName]);

  const scrollHeight = () => {
    try {
      if (
        10 >=
        document.body.clientHeight -
          informationSectionRef?.current?.clientHeight -
          window.scrollY
      ) {
        setChangeHeader(true);
      } else {
        let containerHeight = document.querySelector(".scrollTrigger.allContainer")?.offsetHeight;
        let scrollPosition = window.scrollY || document.documentElement.scrollTop;
        const aboutThirdHeight = aboutSection3Ref.current.offsetHeight;

        if (!(scrollPosition <= containerHeight) && !(scrollPosition >= containerHeight + aboutThirdHeight)) {
          setChangeHeader(true);
        } else {
          setChangeHeader(false);
        }

      }
    } catch (error) {}
  };


  useEffect(() => {
    window.addEventListener("scroll", scrollHeight);
    return () => {
      window.removeEventListener("scroll", scrollHeight);
    };
  }, []);

  const appScrollEvent = (pageName) => {
    ScrollTrigger.killAll();
    if (pageName === "/about") {
      let tl = gsap.timeline({
        scrollTrigger: {
          markers: false,
          trigger: ".rightText",
          start: window.innerWidth >= 1025 ? "top 40%" : "top 80%",
          end: window.innerWidth >= 1025 ? "top 10%" : "top 40%",
          scrub: 0.5,
        },
      });
      tl.from(document.querySelectorAll(".char"), {
        opacity: 0.15,
        duration: 0.3,
        ease: "power1.out",
        stagger: {
          each: 0.4,
        },
      });
    }

    let panels = gsap.utils.toArray(".scrollTrigger");
    panels.forEach((panel, i) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : window.innerWidth <= 1024
              ? "bottom 85%"
              : "bottom 90%", // if it's shorter than the viewport, we prefer to pin it at the top
          pin: true,
          pinSpacing: false,
          scrub: 1,
          // markers: true,
        },
      });
    });

    const lis = document.querySelectorAll(".headerUl li");
    lis.forEach((li) => {
      li.addEventListener("mouseenter", () => {
        const children = li.querySelectorAll(".word");

        children.forEach((char, index) => {
          gsap.to(char, {
            y: "-100%",
            duration: 0.6,
            delay: 0.03 * index,
            ease: "power1.out",
            onComplete: () => {
              const firstWord = char.querySelector(":first-child");
              char.appendChild(firstWord);
              gsap.set(char, { y: 0 });
            },
          });
        });
      });
    });
    if (window.innerWidth > 1024) {
      animateText(".moveTopText", ".leftTopText");
      animateText(".moveBottomText", ".leftBottomText");
    }

    animateTextArtist(".detailMoveStart", ".detailMoveEnd");
    animateTextArtistConnect(
      ".detailMoveStartConnect",
      ".detailMoveEndConnect"
    );
  };

  const animateText = (textClass, targetClass) => {
    let textElements = document.querySelectorAll(textClass);
    let targetDiv = document.querySelectorAll(targetClass);

    if (textElements && targetDiv) {
      let endX = targetDiv[0]?.offsetLeft;
      let endY = targetDiv[0]?.offsetTop + targetDiv[0]?.offsetHeight / 2;

      textElements.forEach((textToMove) => {
        let startX = textToMove?.offsetLeft;
        let startY = textToMove?.offsetTop;
        let finalY = endY - textToMove.offsetHeight / 2;

        gsap.to(textToMove, {
          x: endX - startX,
          y: finalY - (startY + 890),
          scrollTrigger: {
            trigger: ".about",
            // start : "20% top",
            // end: "center center",
            start: "40% 40%",
            end: "60% center",
            scrub: 0.5,
            markers: false,
          },
        });
      });
    }
  };

  const animateTextArtist = (textClass, targetClass) => {
    let textElements = document.querySelectorAll(textClass);
    let targetDiv = document.querySelectorAll(targetClass);
    if (textElements && targetDiv) {
      let endX = targetDiv[0]?.offsetLeft;
      let endY = targetDiv[0]?.offsetTop + targetDiv[0]?.offsetHeight / 2;

      textElements.forEach((textToMove) => {
        let startX = textToMove?.offsetLeft;
        let startY = textToMove?.offsetTop;
        let finalY = endY - textToMove.offsetHeight / 2;

        gsap.to(textToMove, {
          x: endX - startX,
          y: finalY - startY + 20,
          scrollTrigger: {
            // trigger:".about",
            start: "4% top",
            end: "30% center",
            scrub: 0.5,
            markers: false,
          },
        });
      });
    }
  };
  const animateTextArtistConnect = (textClass, targetClass) => {
    let textElements = document.querySelectorAll(textClass);
    let targetDiv = document.querySelectorAll(targetClass);

    if (textElements && targetDiv) {
      let endX = targetDiv[0]?.offsetLeft + targetDiv[0]?.offsetWidth;
      let endY = targetDiv[0]?.offsetTop + targetDiv[0]?.offsetHeight / 2;

      textElements.forEach((textToMove) => {
        let startX = textToMove?.offsetLeft;
        let startY = textToMove?.offsetTop;
        let finalY = endY - textToMove.offsetHeight / 2;

        gsap.to(textToMove, {
          x: endX - (startX - 210),
          y: finalY - (startY - 210),
          scrollTrigger: {
            // trigger:".about",
            start: "4% top",
            end: "30% center",
            scrub: 0.5,
            markers: false,
          },
        });
      });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (pageName == "/about") {
      appScrollEvent(pageName);
    }
  }, [pageName]);

  useEffect(() => {
    if (document.querySelector(".scrollTrigger.allContainer")?.offsetHeight) {
      setDocumentHeight(
        document.querySelector(".scrollTrigger.allContainer")?.offsetHeight
      );
    }
  }, []);

  return (
    <AllContainer>
      <BrowserRouter>
        <Header
          isVisible={isVisible}
          changeHeader={changeHeader}
          headerRef={headerRef}
          setPageName={setPageName}
        />
        <Container className="scrollTrigger allContainer" ref={containerRef}>
          <Routes>
            <Route
              path={"/"}
              element={
                <MainPage
                  appScrollEvent={appScrollEvent}
                  mainSectionRef={mainSectionRef}
                  isVisible={isVisible}
                  informationSectionRef={informationSectionRef}
                  changeHeader={changeHeader}
                />
              }
            />
            <Route
              path={"/about"}
              element={
                // <About
                // />
                <AbountV2 />
              }
            />
            <Route
              path={"/artistList/:type/:page"}
              element={<ArtistList appScrollEvent={appScrollEvent} />}
            />
            <Route
              path={"/artistDetail/:id"}
              element={<ArtistDetail appScrollEvent={appScrollEvent} />}
            />
            <Route
              path={"/mediaList/:page"}
              element={<MediaList appScrollEvent={appScrollEvent} />}
            />
            <Route
              path={"/mediaDetail/:idx"}
              element={<MediaDetail appScrollEvent={appScrollEvent} />}
            />
            <Route
              path={"/audition"}
              element={
                <Audition
                  appScrollEvent={appScrollEvent}
                  documentHeight={documentHeight}
                />
              }
            />
          </Routes>
        </Container>
        <AboutSection $isVisible={pageName == "/about"}>
          <ThirdSection className="scrollTrigger" ref={aboutSection3Ref}>
            <ThirdComponent />
          </ThirdSection>
          <FourthSection className="scrollTrigger">
            <FourthComponent />
          </FourthSection>
        </AboutSection>
        <Footer
          informationSectionRef={informationSectionRef}
          changeHeader={changeHeader}
        />
      </BrowserRouter>
    </AllContainer>
  );
}

const AllContainer = styled.div`
  background-color: ${colors.$000000};
  height: auto;
  position: relative;
  border: 0px solid red;
  @media ${(props) => props.theme.minSmallDesktop} {
    overflow: hidden;
  }
`;

const Container = styled.div`
  width: 100%;
  /* height : max-content; */
  /* position:relative; */
  /* height : ${(props) => props.height}; */
  /* overflow:hidden; */
  background-color: ${colors.$000000};
  /* margin-bottom : 40px; */
  transform: translate(0, 0) !important;
`;
const AboutSection = styled.div`
  background-color:white;
  display: ${(props) => props.$isVisible? "block": "none"};
`;

const ThirdSection = styled.div`
  width: 100%;
  background-color: ${colors.$ffffff};
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  @media ${(props) => props.theme.minSmallDesktop} {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`;

const FourthSection = styled.div`
  width: 100%;
  background-color: ${colors.$000000};
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  @media ${(props) => props.theme.minSmallDesktop} {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`;

export default App;
