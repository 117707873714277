import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors, fonts } from "../../../constants";

import { ReactComponent as Card1 } from "../../../assets/images/svg/card_1.svg";
import { ReactComponent as Card2 } from "../../../assets/images/svg/card_2.svg";
import { ReactComponent as Card3 } from "../../../assets/images/svg/card_3.svg";
import { ReactComponent as Card4 } from "../../../assets/images/svg/card_4.svg";
import { ReactComponent as Card5 } from "../../../assets/images/svg/card_5.svg";
import { ReactComponent as Card6 } from "../../../assets/images/svg/card_6.svg";
import { ReactComponent as Card7 } from "../../../assets/images/svg/card_7.svg";
import { ReactComponent as Prev } from "../../../assets/images/svg/ico_arrow_list_prev.svg";
import { ReactComponent as Next } from "../../../assets/images/svg/ico_arrow_list_next.svg";

const ThirdComponent = () => {
  const [nowPage, setNowPage] = useState(0);
  const cardRef = useRef();

  const cardList = [
    <Card1 />,
    <Card2 />,
    <Card3 />,
    // <Card4 />,
    <Card5 />,
    // <Card6 />,
    // <Card7 />,
  ];

  const goToScroll = async (page) => {
    let width = document.querySelector(`.card${page}`).offsetWidth;
    let resultScroll = 0;
    resultScroll = page * width;
    await cardRef.current.scrollTo({ left: resultScroll});
    setNowPage(page);
  };

  const onScrollEvent = () => {
    let nowScroll = cardRef.current.scrollLeft;
    let width = document.querySelector(`.card0`).offsetWidth;
    let page = Math.ceil(nowScroll / width);
    setNowPage(page);

  }

  return (
    <AllContainer>
      <Top>
        <Title>Production & Equipment Rental</Title>
        <Desc>
          국내 최고 수준의 DJ 장비에 이르기까지.
          <br />
          공연과 페스티벌, 이벤트까지 다양한 영역을 소화할 수 있는 무대 프로덕션
          시스템이 언제나 최상의 컨디션으로 준비 되어 있습니다.
        </Desc>
      </Top>
      <SliderComponent ref={cardRef} onScroll={()=>{onScrollEvent()}}>
        <SliderUl>
          {cardList.map((item, index) => (
            <SliderLi className={`card${index}`} key={index}>
              {cardList[index]}
            </SliderLi>
          ))}
        </SliderUl>
      </SliderComponent>
      <SliderButtonComponent>
        <Circle
          $backgroundColor={`${colors.$ffffff}`}
          $borderColor={`#B0B0B0`}
          onClick={() => {
            console.log("nowpage",nowPage)
              goToScroll(nowPage !== 0 ? nowPage - 1 : 0);
          }}
        >
          <Prev fill="#B0B0B0" />
        </Circle>
        <Circle
          $backgroundColor={`${colors.$444444}`}
          $borderColor={`${colors.$444444}`}
          onClick={() => {
            let limitIndex = window.innerWidth > 1024 ? cardList.length-3 : cardList.length-1;
            if (nowPage != limitIndex) {
              goToScroll(nowPage+1);
            }
          }}
        >
          <Next fill="#ffffff" />
        </Circle>
      </SliderButtonComponent>
    </AllContainer>
  );
};

export default ThirdComponent;

const AllContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 10vh;
`;

const Top = styled.div`
  color: ${colors.$000000};
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60.5vw;
  margin: 0 auto;
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 90%;
    margin-bottom :5rem;
  }

`;
const Title = styled.div`
  font-family: ${fonts.fontFamily.orbitron};
  font-weight: ${fonts.orbitronFont.etc1b.weight};
  font-size: 2.3vw;
  line-height: 75% ;
  @media ${(props) => props.theme.minSmallDesktop} {
    font-size: 8vw;
    line-height: 93.75% ;
  }
`;
const Desc = styled.div`
  font-family: ${fonts.fontFamily.pretendard};
  font-weight: ${fonts.pretendardFont.default1m.weight};
  font-size: 1vw;
  line-height: 150%;
  @media ${(props) => props.theme.minSmallDesktop} {
    font-size: 5vw;
    line-height: 150%;
  }
`;
const SliderComponent = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  will-change: transform;
  scroll-padding: 14vw;
  padding-bottom: 0;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${(props) => props.theme.minSmallDesktop} {
    scrollbar-width: none;
    // height : 60vh;
    padding-left : 3vw;
    padding-right : 3vw;
    // padding-bottom : 3vw;
  }
`;

const SliderUl = styled.ul`
  text-align: left;
  list-style: none;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  width: fit-content;
  padding-top: 70px;
  padding-bottom: 60px;
  padding-left: 4vw;
  padding-right: 19vw;
  @media ${(props) => props.theme.minSmallDesktop} {
    // background-color:red;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 0px;
    padding-bottom: 0px;
    height:100%;
    li{
      svg{
        width: 90vw;
        height:fit-content;
      }
    }
  }
`;

const SliderLi = styled.li`
  flex-shrink: 0;
  scroll-snap-align: start;
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 90vw;
    height:100%;
  }

`;

const SliderButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  justify-content: right;
  gap: 20px;
  padding-bottom: 58px;
  @media ${(props) => props.theme.minSmallDesktop} {
    width: 100%;
    padding : 0 3vw;
    gap: 22px;
    padding-bottom: 60px;
  }
`;

const Circle = styled.div`
  cursor: pointer;
  padding: 18px;
  background-color: ${(props) => props.$backgroundColor};
  border: 1px solid ${(props) => props.$borderColor};
  border-radius: 150px;
  display: flex;
  @media ${(props) => props.theme.minSmallDesktop} {
    padding: 10px;
  }
`;
