const colors = {
    $ffffff : "#ffffff",
    $000000 : "#000000",
    $aaaaaa : "#aaaaaa",
    $8622c4 : "#8622c4",
    $5622c4 : "#5622c4",
    $888888 : "#888888",
    $444444 : "#444444",
    $A082DC : "#A082DC",
    $8622C4 : "#8622C4",
    $121212 : "#121212",
    $E72626 : "#E72626",
}

export default colors;