import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from "../constants";

const FadeText = ({isVisible, topText, bottomText, fontColor}) => {
  return (
    <FadeComponent $isview={isVisible?true:false} fontcolor={fontColor}>
      <TextComponent>{topText}</TextComponent>
      <TextComponent>{bottomText}</TextComponent>
    </FadeComponent>
  )
}

const FadeComponent = styled.div`
  color:${props => props.fontcolor};
  font-family:${fonts.fontFamily.orbitron};
  /* font-size: 110px; */
  font-size: 5.6vw;
  line-height: 130px;
  /* letter-spacing: -1.1px; */
  /* font-size: ${fonts.orbitronFont.etc1b.size}; */
  font-weight:${fonts.orbitronFont.etc1b.weight};
  animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s;
  -moz-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; 
  -webkit-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; 
  -o-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; 
  visibility: ${(props) => (props.$isview ? "visible" : "hidden")};
  z-index : 1;
  display : flex;
  gap : 30px;
  align-items: center;
  justify-content:center;
  opacity: ${props => props.$isview ? "1" : "0"};
  @media ${props => props.theme.minSmallDesktop}{
    /* font-size: ${fonts.orbitronFont.etc3b.size}; */
    font-size: 9vw;
    font-weight: ${fonts.orbitronFont.etc3b.weight};
    padding : 60px 20px;
    line-height: unset;
    letter-spacing: 0px;
    gap : 10px;
    align-items: unset;
    flex-direction:column;
    text-align:left;
  }
`;

const TextComponent = styled.div`
  height: max-content;
`;


export default FadeText