import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { colors, fonts } from '../../constants';

import Connect from './components/Connect';
import { ReactComponent as BulletIcon } from "../../assets/images/svg/bullet.svg";
import ArtistPhoto from './components/ArtistPhoto';
import { BackToList } from '../../components';
import axios from 'axios';
import {returnIsCenter} from "../../lib/common";


const ArtistDetail = ({ appScrollEvent }) => {

    const { id } = useParams();

    const imgRef = document.querySelector(".imgrefs");
    const lineRef = useRef();
    const [isActive, setIsActive] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    const [left, setLeft] = useState();
    const [top, setTop] = useState();
    const [right, setRight] = useState();
    const [artistDetail, setArtistDetail] = useState({})
    const [careerList, setCareerList] = useState([]);
    const [artistDetailImage, setArtistDetailImage] = useState([])


    const handleResize = () => {
        const imgRefs = document.querySelector(".imgrefs")
        setLeft(imgRefs?.offsetLeft - (imgRefs?.offsetLeft * 0.55))
        setTop(imgRefs?.offsetTop + 80)
        // setRight(imgRefs?.offsetLeft - 250)
        setRight(imgRefs?.offsetLeft - (imgRefs?.offsetLeft * 0.5))
        setIsMobile(window.innerWidth <= 1024)
    }


    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
        };
    }, []);

    const getObserve = () => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsActive(true);
                } else {
                    setIsActive(false);
                }
            });
        });

        if (lineRef.current) {
            observer.observe(lineRef.current);
        }

        return () => {
            if (lineRef.current) {
                observer.unobserve(lineRef.current);
            }
        };
    }

    useEffect(() => {
        getObserve();
    }, []);

    const getArtistDetail = async () => {
        let url = `${process.env.REACT_APP_GMAIL_API}/artist/detail/${id}`
        let result = await axios.get(`${url}`);
        setArtistDetail(result?.data?.info)
        setArtistDetailImage(result?.data?.data)
        appScrollEvent();
        if (window.innerWidth <= 1024) {
            setTimeout(() => {
                appScrollEvent();
            }, 250);
        }

    }

    const getCareerList = () => {
        const list = artistDetail?.careerList?.split("<br>");
        setCareerList(list);
    }

    useEffect(() => {
        getArtistDetail();
    }, [])
    useEffect(() => {
        getCareerList();
    }, [artistDetail])


    return (
        <AllContainer>
            {
                !isMobile ?
                    <ArtistMainSection>
                        <ArtistNameComponents className='detailMoveStart' position={"absolute"} left={left} top={top}>
                            <ArtistName $ismobile={false} position={"relative"} left={left} top={top}>{artistDetail.name}</ArtistName>
                        </ArtistNameComponents>
                        <ArtistMainImageComponent className='imgrefs'>
                            <ArtistMainImage
                                src={artistDetail.detailImage}
                                $iscenter={returnIsCenter(artistDetail?.name)}

                            />
                        </ArtistMainImageComponent>
                        <ArtistConnectComponent right={right} className='detailMoveStartConnect' >
                            <Connect artistDetail={artistDetail} $ismobile={false} className={""}/>
                        </ArtistConnectComponent>
                    </ArtistMainSection>
                    : ""
            }
            <IntroduceSection>
                <IntroduceSectionWrap>
                    <RightBox>
                        <MoveEndBox>
                            <JobText>{artistDetail.type}</JobText>
                            <ArtistName className='detailMoveEnd' $ismobile={!isMobile} position={"relative"}>{artistDetail.name}</ArtistName>
                        </MoveEndBox>
                        <RightBoxImage>
                            <ArtistMainImage
                                src={artistDetail.detailImage}
                                $iscenter={returnIsCenter(artistDetail?.name)}
                            />
                        </RightBoxImage>
                        <ConnectWrap className='detailMoveEndConnect'>
                            <Connect artistDetail={artistDetail} $ismobile={!isMobile} className=''/>
                        </ConnectWrap>
                    </RightBox>
                    <LeftBox>
                        <IntroductionWrap>
                            {
                                artistDetail.introduce
                            }
                        </IntroductionWrap>
                        <CareerWrap>
                            <LineComponent ref={lineRef} className={isActive ? "isActive" : ""} />
                            <CareerText>Artist Career</CareerText>
                            <CareerList>
                                {
                                    careerList?.map((item, index) => (
                                        !/^\s*$/g.test(item) ?
                                            <CareerItem key={index}>
                                                <IconDiv>
                                                    <BulletIconSvg />
                                                </IconDiv>
                                                <CareerItemText>
                                                    {item}
                                                </CareerItemText>
                                            </CareerItem> : ""
                                    ))
                                }
                            </CareerList>
                        </CareerWrap>
                    </LeftBox>
                </IntroduceSectionWrap>
            </IntroduceSection>
            <ArtistPhoto artistDetailImage={artistDetailImage} iscenter={returnIsCenter(artistDetail?.name)}
            />
            <BackToList />
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100%;
    height : 100%;
`;

const ArtistMainSection = styled.div`
    width : 100%;
    height : 100vh;
    position : relative;
    display:flex;
    justify-content:center;
    align-items:center;
`;

const ArtistNameComponents = styled.div`
    position : absolute;
    animation : leftFadeIn 1s ;
    z-index : 1;
    left : ${props => props.position == "absolute" ? props.left + "px" : ""};
    top : ${props => props.position == "absolute" ? props.top + "px" : ""};
`;

const ArtistName = styled.div`
    opacity : ${props => props.$ismobile ? "0" : "1"};
    color :${colors.$ffffff};
    font-family : ${fonts.fontFamily.orbitron};
    font-size : ${fonts.orbitronFont.etc1b.size};
    font-weight : ${fonts.orbitronFont.etc1b.weight};
    /* position:${props => props.position};
    left : ${props => props.position == "absolute" ? props.left + "px" : ""};
    top : ${props => props.position == "absolute" ? props.top + "px" : ""}; */
    z-index:1;
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 16vw;
    }
`;

const ArtistMainImageComponent = styled.div`
    position: relative;
    /* width : 800px; */
    width : 50%;
    height : 90vh;
    border-radius: 40px;
`;

const ArtistMainImage = styled.div`
    width : 100%;
    height : 100%;
    background : url(${props => props.src}) no-repeat ${props => props.$iscenter ? "center" : ""};
    background-repeat : no-repeat;
    background-size : cover; 
    background-position: top;
    /* background-image : url("https://d2eskwibiybmb4.cloudfront.net/ent-home/mainTop/asd.png"); */
    position : absolute;
    left : 0;
    top : 0;
    /* background-image : url(${props => props.src});
    background-repeat : no-repeat;
    background-size : cover;
    background-position-x: center;
    background-position-y: center; */
    border-radius: 40px;
    @media ${props => props.theme.minSmallDesktop} {
        border-radius: 15px;
        background : url(${props => props.src}) no-repeat ${props => props.$iscenter ? "center" : ""};
        background-repeat : no-repeat;
        background-size : cover;
        background-position: top;
    }

   
`;

const ArtistConnectComponent = styled.div`
    position : absolute;
    z-index : 1;
    animation : rightFadeIn 1s ;
    bottom : 15%;
    right : ${props => props.right}px ;
`;

const IntroduceSection = styled.div`
    /* position: relative; */
    width : 100%;
    padding : 160px 60px;
    height : 100vh;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 100px 20px;
        height : auto;
    }

`;
const IntroduceSectionWrap = styled.div`
    width : 100%;
    height : 100%;
    display : flex;

    @media ${props => props.theme.minSmallDesktop} {
        flex-direction : column;
        height : auto;
        gap : 60px;
    }
`;

const MoveEndBox = styled.div`
    display : flex;
    flex-direction: column;
    gap : 10px;
`;

const JobText = styled.div`
    color :${colors.$ffffff};
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default3m.size};
    font-weight : ${fonts.pretendardFont.default3m.weight};
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 5vw;
    }

`;

const RightBox = styled.div`
    width : 40%;
    height : 100%;
    display : flex;
    flex-direction: column;
    gap : 40px;
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
    }



`

const RightBoxImage = styled.div`
    display: none;
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
        height : 100vw;
        position : relative;
        display: flex;
        justify-content:center;
    }

`

const ConnectWrap = styled.div`
    position:relative;
    width : max-content;
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
    }
`;

const LeftBox = styled.div`
    width : 60%;
    height : 100%;
    display :flex;
    flex-direction:column;
    justify-content:end;
    gap : 40px;
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
        padding : 20px 0;
    }
`

const IntroductionWrap = styled.div`
    width : 100%; 
    color :${colors.$aaaaaa};
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default4r.size};
    font-weight : 400;
    line-height : 1.3;
    /* font-weight : ${fonts.pretendardFont.default4r.weight}; */
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 5vw;
    }
`;

const LineComponent = styled.div`
    border-top : 1px solid ${colors.$ffffff};
    &.isActive{
        animation: line 1.5s forwards;
    }
`;

const CareerWrap = styled.div`
    display : flex;
    flex-direction: column;
    gap : 20px;
`;

const CareerText = styled.div`
    color :${colors.$ffffff};
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default3m.size};
    font-weight : ${fonts.pretendardFont.default3m.weight};
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 4.5vw;
    }
`;

const CareerList = styled.div`
    display : flex;
    flex-direction: column;
    max-height : 240px;
    overflow-y:scroll;
    gap : 10px;
    padding-top  : 5px;

`;

const CareerItem = styled.div`
    display : flex;
    align-items:center;
    gap : 10px;
`;

const IconDiv = styled.div`
    display : flex;
    align-items:center;
    justify-content:center;
    height : ${fonts.pretendardFont.default4r.size};
    @media ${props => props.theme.minSmallDesktop} {
        align-items:start;
    }
`;

const BulletIconSvg = styled(BulletIcon)`
`;

const CareerItemText = styled.div`
    width : 100%;
    color :${colors.$ffffff};
    /* white-space:pre-line;
    word-wrap: break-word; */
    position:relative;
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default4r.size};
    height : ${fonts.pretendardFont.default4r.size};
    margin-top : 0.1rem;
    line-height : 16px;
    font-weight : 400;
    padding: 0;
    display : flex;
    align-items:center;
    /* font-weight : ${fonts.pretendardFont.default4r.weight}; */
    @media ${props => props.theme.minSmallDesktop} {
        font-size : 16px;
        height: max-content;
        margin-top : 0;
    }
`;


export default ArtistDetail