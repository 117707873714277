// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    box-sizing: border-box;
}

.ul{
    flex-grow:0;
    transform-style: preserve-3d;
    width: 22vw;
    height: 22vw;
    /* width: 27.8645833333vw;
    height: 27.8645833333vw; */
    will-change:transform;
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/components/slick.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IAEX,4BAA4B;IAC5B,WAAW;IACX,YAAY;IACZ;8BAC0B;IAC1B,qBAAqB;AACzB","sourcesContent":["* {\n    box-sizing: border-box;\n}\n\n.ul{\n    flex-grow:0;\n    -webkit-transform-style: preserve-3d;\n    transform-style: preserve-3d;\n    width: 22vw;\n    height: 22vw;\n    /* width: 27.8645833333vw;\n    height: 27.8645833333vw; */\n    will-change:transform;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
