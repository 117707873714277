import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import { ReactComponent as PrevIcon } from "../../../assets/images/svg/ico_arrow_list_prev.svg"
import { ReactComponent as NextIcon } from "../../../assets/images/svg/ico_arrow_list_next.svg"
import { Link, useNavigate } from 'react-router-dom';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';



const Paging = ({ nowPage, totalPage }) => {

  const [page, setPage] = useState(nowPage);

  const navigator = useNavigate()

  const goNavigator = () => {
    if(page <= totalPage){
      navigator(`/mediaList/${page}`)
      ScrollTrigger?.enable()
    }else{
      alert("페이지 범위를 벗어납니다.")
    }
  }

  const inputPage = (value) => {
    const numberRegex = /^[0-9]+$/;
    const result = numberRegex.test(value);
    if(result){
      setPage(value)
    }else if(value == ""){
      setPage(value)
    }
  }

  useEffect(() => {
    setPage(nowPage)
  }, [nowPage])

  return (
    <AllContainer>
      <Wrap>
        <Circle $isactive={nowPage > 1}>
          <Link to={`/mediaList/${nowPage > 1 ? Number(nowPage) - 1 : nowPage}`}>
            <PrevIcon />
          </Link>
        </Circle>
        <PageComponent>
          <NowPage onKeyPress={(e) => {
            if (e.key == "Enter") {
              goNavigator()
            }
          }} value={page} onChange={(e) => { inputPage(e.target.value) }}
            onFocus={(e) => {
                ScrollTrigger?.disable()
                e.target.focus();
            }}
            onBlur={(e) => {
              ScrollTrigger?.enable()
              e.target.blur();
            }}
          />
          <AllPage>/&nbsp;{totalPage}</AllPage>
        </PageComponent>
        <Circle $isactive={nowPage < totalPage}>
          <Link to={`/mediaList/${nowPage < totalPage ? Number(nowPage) + 1 : nowPage}`}>
            <NextIcon />
          </Link>
        </Circle>
      </Wrap>
    </AllContainer>
  )
}

const AllContainer = styled.div`
  width : 100%;
  display : flex;
  justify-content:center;
  align-items:center;
  @media ${props => props.theme.minSmallDesktop} {
    width : 100%;
    height : 50px;
    top:500px;
  }
`;

const Wrap = styled.div`
  /* width : 50%; */
  height : 100%;
  display : flex;
  align-items : center;
  gap : 20px;
  @media ${props => props.theme.minSmallDesktop} {
    /* justify-content:space-between; */
    padding : 20px;
    gap : 10px;
  }
`;

const Circle = styled.div`
  background-color :${props => props.$isactive ? colors.$ffffff : colors.$444444};
  border-radius : 60px;
  display : flex;
  align-items:center;
  justify-content:center;
  padding : 10px;
  a{
    display : flex;
    align-items:center;
  }
`;

const PageComponent = styled.div`
  width : 100%;
  display : flex;
  justify-content:center;
  align-items:center;
  gap: 10px;
  @media ${props => props.theme.minSmallDesktop} {
    justify-content:unset;
    width : min-content;
  }
`;

const NowPage = styled.input`
  width : 8rem;
  color : ${colors.$ffffff};
  font-family : ${fonts.fontFamily.pretendard};
  font-size : ${fonts.pretendardFont.default1m.size};
  font-weight : ${fonts.pretendardFont.default1m.weight};
  border : 2px solid ${colors.$ffffff};
  padding : 18px 10px;
  border-radius:60px;
  display : flex;
  justify-content:center;
  align-items:center;
  line-height: 24px;
  background-color : transparent;
  text-align:center;
  outline: none;
  &:focus {
        border : 2px solid ${colors.$ffffff};
    }

  @media ${props => props.theme.minSmallDesktop} {
    width : 6rem;
    height : 44px;
    padding : 0px 0px;
    border-radius:15px;
  }
  
`;

const AllPage = styled.div`
  color : ${colors.$aaaaaa};
  font-family : ${fonts.fontFamily.pretendard};
  font-size : ${fonts.pretendardFont.default1m.size};
  font-weight : ${fonts.pretendardFont.default1m.weight};
  line-height: 24px;
`;


export default Paging