import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../constants';
import { Link } from 'react-router-dom';


const MobileNavBar = ({ mobileNav }) => {

    const navItem = [
        {
            link: "/about",
            name: "ABOUT"
        },
        {
            link: "/artistList/all/1",
            name: "ARTIST"
        },
        {
            link: "/mediaList/1",
            name: "MEDIA"
        },
        {
            link: "/audition",
            name: "AUDITION"
        },
    ]

    return (
        <MobileNavBarContainer $mobilenav={mobileNav ? true : false}>
            <MobileNavBarList>
                {navItem.map((item, index) => (
                    <MobileNavBarItem key={index}>
                        <Link to={item.link}>
                            {item.name}
                        </Link>
                    </MobileNavBarItem>
                ))}
            </MobileNavBarList>
        </MobileNavBarContainer>
    )
}


const MobileNavBarContainer = styled.div`
    display: ${props => props.$mobilenav ? "block" : "none"};
    width: 100vw;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.90);
    color:${colors.$ffffff};
    a{
        color:${colors.$ffffff};
    }
    position:absolute; 
    z-index: 99;
`;

const MobileNavBarList = styled.div`
    width: 100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    gap : 20px;
    padding-top : 50px;
`;

const MobileNavBarItem = styled.div`
  text-align:center;
  font-family:${fonts.fontFamily.orbitron};
  font-size:${fonts.orbitronFont.etc6b.size};
  font-weight:${fonts.orbitronFont.etc6b.weight};
  /* margin-bottom : 5rem; */
  padding : 20px 0;
`;

export default MobileNavBar