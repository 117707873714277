import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../constants';
import CheckBoxList from './components/CheckBoxList';
import BasicInfoInputWrap from './components/BasicInfoInputWrap';
import FileUpload from './components/FileUpload';
import EmptyBox from "../../components/EmptyBox";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Audition = ({ appScrollEvent, documentHeight }) => {

    const navigator = useNavigate();

    const [width, setWidth] = useState(0);
    const [checkBoxList, setCheckBoxList] = useState([
        {
            name: "Actor",
            isCheck: false,
        }, {
            name: "Cheerleader",
            isCheck: false,
        }, {
            name: "DJ",
            isCheck: false,
        }, {
            name: "MC",
            isCheck: false,
        }, {
            name: "Model",
            isCheck: false,
        }, {
            name: "Performance",
            isCheck: false,
        }, {
            name: "Singer",
            isCheck: false,
        },
    ])

    /*
        0 : error
        1 : success
        2 : not
    */
    const [basicInfoData, setBasicInfoData] = useState([
        {
            placeHolder: "이름 (필수)",
            value: "",
            isReqire: true,
            type: "name",
            status : 2,
        },
        {
            placeHolder: "연락처 (필수)",
            value: "",
            isReqire: true,
            type: "phone",
            status : 2,
        },
        {
            placeHolder: "이메일 (필수)",
            value: "",
            isReqire: true,
            type: "email",
            status : 2,
        },
        {
            placeHolder: "SNS or 포트폴리오 URL (필수)",
            value: "",
            isReqire: true,
            type: "url",
            status : 2,

        }, {
            placeHolder: "기타 내용 (선택)",
            value: "",
            isReqire: 2,
            type: "description",
            status : 1,
        }
    ]);

    const [files, setFiles] = useState({
        name: "",
        buffer: ""
    });
    const [sendFile, seSendFile] = useState()
    const [loading, setLoading] = useState(
        false
        // true
        );

    const onClickCheckBox = (index) => {
        let prevData = [...checkBoxList];
        prevData[index].isCheck = !prevData[index].isCheck
        setCheckBoxList(prevData);
    }

    const onChangeBasicInfo = (value, index) => {
        let prevData = [...basicInfoData];
        prevData[index].value = value;
        if(value == ''){
            prevData[index].status = 2;
        }else{
            let bool = false;
            let regex;
            switch (index) {
                case 0:
                    bool = value != "" ? true : false 
                    break;
                case 1:
                    bool = /^[\d-]+$/.test(value);
                    break;
                case 2:
                    regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}')
                    bool = regex.test(value);
                    break;
                case 3:
                    bool = value != "" ? true : false 
                    break;
                case 4:
                    
                    break;
                default:
                    break;
            }
            bool = bool ? 1 : 0
            prevData[index].status = bool;
        }

        setBasicInfoData(prevData);
    }

    const onChangeFile = (file) => {
        if (file != "" && file.size <=2.5e+7) {
            seSendFile(file)
            const reader = new FileReader();
            try {
                reader.readAsDataURL(file);
                reader.onloadend = (event) => [
                    setFiles(
                        {
                            name: file.name,
                            buffer: event.target?.result
                        }
                    )
                ]
            } catch (error) {
                console.log(error)
            }
        } else {
            setFiles(
                {
                    name: "",
                    buffer: ""
                }
            )
            seSendFile()

        }
        appScrollEvent();
    }

    const setButtonWidth = () => {
        const width = document.querySelector('.supportBtn');
        setWidth(width.offsetWidth);
    }

    const onClickSendMail = async () => {
        let findCheck = checkBoxList.findIndex((e) => e.isCheck === true);
        let findBasic = basicInfoData.findIndex((e) => e.isReqire === true && e.value === "" && (e.status === 2 || e.status ===0));
        const name = basicInfoData.find((e) => e.type == "name")
        const phone = basicInfoData.find((e) => e.type == "phone")
        const email = basicInfoData.find((e) => e.type == "email")
        const url = basicInfoData.find((e) => e.type == "url")
        const description = basicInfoData.find((e) => e.type == "description")
        const file = sendFile;


        if (findCheck != -1 && findBasic == -1) {
            const isCheckList = checkBoxList.filter((e) => { return e.isCheck });
            let subject = '';
            for (let i = 0; i < isCheckList.length; i++) {
                subject = subject + (i == 0 ? "" : ",") + isCheckList[i].name;
            }
            const newForm = new FormData();
            newForm.append("to", "mammothent0905@gmail.com");
            newForm.append("subject", subject);
            newForm.append("name", name.value);
            newForm.append("phone", phone.value);
            newForm.append("email", email.value);
            newForm.append("url", url.value);
            newForm.append("description", description.value);
            newForm.append("file", file);
            try {
                setLoading(true)
                const result = await axios.post(`${process.env.REACT_APP_GMAIL_API}/adapter/mail`, newForm, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if(result.status == 200){
                    setLoading(false)
                    navigator('/')
                }
            } catch (error) {
                console.log("error",error)
            }

        }else if(findCheck == -1 ){
            alert("지원분야를 선택해주세요")
        }else if(findBasic != -1){

        }

    }

    useEffect(() => {
        setButtonWidth();
        window.addEventListener("resize", setButtonWidth);
        return (
            window.removeEventListener('resize', setButtonWidth)
        )
    }, [])

    useEffect(() => {
        appScrollEvent();
    }, [])

    return (
        <AllContainer>
            <AuditionWrap>
                <Title>
                    <NormalText>
                        <TopText>
                            Mammoth-ENT는 함께&nbsp;
                        </TopText>
                        <BottomText>
                            능력을 발산하고 성장할
                        </BottomText>
                    </NormalText>
                    <BoldText>
                        <TopText>
                            아티스트들을 항상 기다리고 
                        </TopText>
                        <BottomText>
                            있습니다.
                        </BottomText>
                    </BoldText>
                </Title>
                <AuditionInputWrap>
                    <SupportAreasComponents>
                        <SubTitle><WhiteText>지원 분야</WhiteText><GrayText>(필수 / 다중 선택 가능)</GrayText></SubTitle>
                        <CheckBoxList list={checkBoxList} onClick={onClickCheckBox} />
                    </SupportAreasComponents>
                    <BasicInfoComponents>
                        <SubTitle><WhiteText>기본 정보</WhiteText></SubTitle>
                        <BasicInfoInputWrap data={basicInfoData} onChange={onChangeBasicInfo} appScrollEvent={appScrollEvent} documentHeight={documentHeight} />
                    </BasicInfoComponents>
                    <FileUploadComponents>
                        <SubTitle><WhiteText>파일 첨부</WhiteText><GrayText>(여러개의 파일을 올리실 경우, 압축해서 ZIP 파일로 올려주세요. 최대 25MB)</GrayText></SubTitle>
                        <FileUpload onChange={onChangeFile} files={files} />
                    </FileUploadComponents>
                    <ButtonWrap width={width} onClick={onClickSendMail}>
                        <SupportBtn width={width} $loading={loading} className='supportBtn' type='submit'>
                            {
                                loading 
                                ?
                                "전송 중..."
                                :"지원하기"
                            }
                        </SupportBtn>
                    </ButtonWrap>
                </AuditionInputWrap>
            </AuditionWrap>
            <EmptyBox />
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100vw;
    height : auto;
    padding-top : 180px;
    @media ${props => props.theme.minSmallDesktop} {
        padding-top : 80px;
        margin-bottom : 80px;
    }
`;

const AuditionWrap = styled.div`
    width : 80vw;
    display :flex;
    justify-content:center;
    align-items : center;
    margin : 0 auto;
    flex-direction : column;
    gap : 100px;
    @media ${props => props.theme.minSmallDesktop} {
        width : 90%;
        gap : 40px;
    }
`;

const Title = styled.div`
    width : 100%;
    line-height: normal;
    font-family : ${fonts.fontFamily.pretendard};
    font-size  : 40px;
    color : ${colors.$ffffff};
    display :flex;
    flex-direction : column;
    @media ${props => props.theme.minSmallDesktop} {
        font-size  : 24px;
    }

`;

const TopText = styled.span``;

const BottomText = styled.span``;

const NormalText = styled.div`
    font-weight : 400;
    line-height: normal;
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        gap : 0px;
        display :flex;
        flex-direction : column;
    }
`;

const BoldText = styled.div`
    font-weight : 700;
    line-height: normal;
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        gap : 0px;
        display :flex;
        flex-direction : column;
    }
`;

const AuditionInputWrap = styled.div`
    width : 100%;
    display  : flex;
    flex-direction : column;
    gap : 40px;
`;

const SubTitle = styled.div`
    font-family : ${fonts.fontFamily.pretendard};
    color : ${colors.$ffffff};
    font-size  : ${fonts.pretendardFont.default3r.size};
    font-weight : ${fonts.pretendardFont.default3r.weight};
    line-height: 18px;
    display  : flex;
    flex-direction : row;
    gap : 10px;
    @media ${props => props.theme.minSmallDesktop} {
        flex-direction : column;
        gap : 4px;
    }

`;

const WhiteText = styled.div`
    font-family : ${fonts.fontFamily.pretendard};
    color : ${colors.$ffffff};
    font-size  : ${fonts.pretendardFont.default3r.size};
    font-weight : 400;
    /* font-weight : ${fonts.pretendardFont.default3r.weight}; */
    line-height: 18px;
`;

const GrayText = styled.span`
    font-family : ${fonts.fontFamily.pretendard};
    color : ${colors.$aaaaaa};
    font-size  : ${fonts.pretendardFont.default4r.size};
    font-weight : 400;
    /* font-weight : ${fonts.pretendardFont.default4r.weight}; */
    line-height: 18px;
`;

const SupportAreasComponents = styled.div`
    display  : flex;
    flex-direction : column;
    gap : 20px;
`;

const BasicInfoComponents = styled.div`
    display  : flex;
    flex-direction : column;
    gap : 20px;
`;

const FileUploadComponents = styled.div`
    display  : flex;
    flex-direction : column;
    gap : 20px;
`;

const ButtonWrap = styled.div`
    display : flex;
    justify-content : right;
    position : relative;
    overflow : hidden;

    &::before{
        content: "";
        position: absolute;
        opacity: 0;
        background: rgba(67,85,75,.2);
        border-radius: 35%;
        right: 0px;
        bottom: -200%;
        width : ${props => props.width}px;
        height : 200%;
        transition: all .7s;
        cursor: pointer;
    }
    &:hover::before{
        bottom: -50%;
        opacity: 1;
    }
`;

const SupportBtn = styled.button`
    font-family : ${fonts.fontFamily.pretendard};
    color : ${colors.$000000};
    font-size  : 18px;
    font-weight : 700;
    background-color : ${colors.$A082DC};
    /* padding : ${props => props.$loading ? "20.5px 80px": "30px 69px"}; */
    border-radius: 20px;
    cursor:pointer;
    overflow : hidden;
    text-align:center;
    border-width : 0px;
    height : 70px;
    padding : 0 68px;
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
        border-radius: 15px;
        height : 44px;

    }

`;



export default Audition