import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import Slick from './components/Slick';
import Intro from './components/Intro';
import SlickMobile from './components/SlickMobile';
import AuditionSection from './components/AuditionSection';
import Information from './components/Information';
import axios from 'axios';


const MainPage = ({ mainSectionRef, isVisible, appScrollEvent }) => {

  const [artist, setArtist] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const getArtist = async () => {
    let url = `${process.env.REACT_APP_GMAIL_API}/main?take=12`;
    let result = await axios.get(`${url}`);
    setArtist(result.data.data)
  }

  const getOs = () => {
    let result = false;
    const filter = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
      if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
        result = true;
      } else {
        result = false;
      }
    } else {
      result = false;
    }

    if (window.innerWidth <= 1024) {
      result = true;
    }
    setIsMobile(result);
  }

  useEffect(() => {
    window.addEventListener('resize', getOs);

    return () => {
      window.removeEventListener('resize', getOs);
    };
  }, []);



  useEffect(() => {
    getArtist()
    getOs()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      appScrollEvent();
    }, 30)
  }, [artist])

  return (
    <Container>
      <Intro mainSectionRef={mainSectionRef} isVisible={isVisible} />
      {
        isMobile
          ?
          <MobileSlickComponent>
            <SlickMobile list={artist} />
          </MobileSlickComponent>
          :
          <Slick list={artist} />
      }
      <AuditionSection />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const MobileSlickComponent = styled.div`
    width: 100%;
    height: auto;
    display:flex;
    align-items: center;
`;


export default MainPage