

const fontFamily = {
    pretendard : "Pretendard",
    montserrat : "Montserrat",
    orbitron : "Orbitron"
}

const pretendardFont = {
    default1m : {
        // size : "1.5rem",
        size : "24px",
        weight : "400"
    },
    default2m : {
        // size : "1.25rem",
        size : "20px",
        weight : "400"
    },
    default3m : {
        // size : "1.125rem",
        size : "18px",
        weight : "400"
    },
    default4m : {
        // size : "1rem",
        size : "16px",
        weight : "400"
    },
    default3r : {
        // size : "1.125rem",
        size : "18px",
        weight : "300"
    },
    default4r : {
        // size : "1rem",
        size : "16px",
        weight : "300"
    },
}

const montserratFont = {
    english1m : {
        // size : "1.5rem",
        size : "24px",
        weight : "500"
    },
    english2m : {
        // size : "1.25rem",
        size : "20px",
        weight : "500"
    },
    english3b : {
        // size : "1rem",
        size : "16px",
        weight : "700"
    },
    english4b : {
        size : "12px",
        // size : "12px",
        weight : "700"
    },
    english3m : {
        // size : "1rem",
        size : "16px",
        weight : "500"
    },
    english3r : {
        // size : "1rem",
        size : "16px",
        weight : "400"
    },
    english4r : {
        // size : "0.75rem",
        size : "12px",
        weight : "400"
    },
}


const orbitronFont = {
    etc1b : {
        size : "6.875rem",
        // size : "110px",
        weight : "700"
    },
    etc2b : {
        // size : "2.5rem",
        size : "40px",
        weight : "700"
    },
    etc3b : {
        // size : "2rem",
        size : "32px",
        weight : "700"
    },
    etc4m : {
        // size : "1.875rem",
        size : "30px",
        weight : "500"
    },
    etc5b : {
        // size : "1.75rem",
        size : "28px",
        weight : "700"
    },
    etc6b : {
        // size : "1.375rem",
        size : "22px",
        weight : "700"
    },
    etc7m : {
        // size : "1.125rem",
        size : "18px",
        weight : "500"
    },
}

const fonts = {
    fontFamily,
    pretendardFont,
    montserratFont,
    orbitronFont,
}

export default fonts;