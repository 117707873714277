import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';

const Information = ({informationSectionRef, changeHeader}) => {
  return (
    <Outter ref={informationSectionRef}>
      <BgContainer>
        <InformationContainer>
          <LeftBox>
            <Title>Information</Title>
            <Content>
              <TopContent>
                <KrAddress>
                  서울특별시 강남구 도산대로28길 26<br />맘모스엔터테인먼트
                </KrAddress>
                <EnAddress>
                  MAMMOTH Entertainment 26, Dosan-daero 28-gil, Gangnam-gu, Seoul,<br />Republic of Korea
                </EnAddress>
              </TopContent>
              <MiddleContent>+82. 02. 518. 0905</MiddleContent>
              <BottomContent>
                <ColumnContent>
                  <ContactContent>
                    <ColumnTopContent>Contact</ColumnTopContent>
                    <ColumnBottomContent>
                      <div>
                        MON - FRI 9AM - 6PM

                      </div>
                      <div>
                        SAT. SUN. HOLIDAY OFF

                      </div>
                    </ColumnBottomContent>
                  </ContactContent>
                  <EmailContent>
                    <ColumnTopContent>Email</ColumnTopContent>
                    <ColumnBottomContent>
                      mammothent0905@gmail.com
                    </ColumnBottomContent>
                  </EmailContent>
                </ColumnContent>
                <FooterContent>ⓒ MAMMOTH Entertainment. All rights reserved.</FooterContent>
              </BottomContent>
            </Content>
          </LeftBox>
          <RightBox>
            <RightBoxImage />
          </RightBox>
        </InformationContainer>
      </BgContainer>
        <FadeComponent $isview={changeHeader}>
          MAMMOTH ENTERTAINMENT
        </FadeComponent>
    </Outter>
  )
}

const Outter = styled.div`
  width : 100%;
  height: 100vh;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const BgContainer = styled.div`
  width : 100%;
  height: 100%;
  display:flex;
  align-items:center;
  justify-content:right;
`;

const InformationContainer = styled.div`
  width: 80%;
  height: 65%;
  display: flex;
  flex-direction:row;
  gap: 35px;
  align-items:center;
`;

const LeftBox = styled.div`
  width : 50%;
  height: 90%;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  color:${colors.$000000};
  gap: 40px;
`;

const Title = styled.div`
  font-family : ${fonts.fontFamily.orbitron};
  font-size : ${fonts.orbitronFont.etc2b.size};
  font-weight : ${fonts.orbitronFont.etc2b.weight};

`;

const Content = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 30px;
  height: 100%;
`;

const TopContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const KrAddress = styled.div`
  font-family : ${fonts.fontFamily.pretendard};
  font-size : ${fonts.pretendardFont.default1m.size};
  font-weight : ${fonts.pretendardFont.default1m.weight};
  line-height: 36px;
`;
const EnAddress = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english4r.size};
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 24px;
`;


const MiddleContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english1m.size};
  font-weight : ${fonts.montserratFont.english1m.weight};
  line-height: 24px;
`;

const BottomContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 60px;
`;

const ColumnContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 20px;
`;
const ColumnTopContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english4b.size};
  font-weight : ${fonts.montserratFont.english4b.weight};
  line-height: 16px;

`;
const ColumnBottomContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english4r.size};
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 16px;

`;

const ContactContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const EmailContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  gap : 10px;
`;

const FooterContent = styled.div`
  font-family : ${fonts.fontFamily.montserrat};
  font-size : ${fonts.montserratFont.english4r.size};
  font-weight : ${fonts.montserratFont.english4r.weight};
  line-height: 16px;
  color:${colors.$aaaaaa};
`;



const RightBox = styled.div`
  width : 50%;
  height: 100%;
  border-top-left-radius : 45px;
  border-bottom-left-radius : 45px;
  overflow:hidden;
`;

const RightBoxImage = styled.img``;

const FadeComponent = styled.div`
  bottom:0px;
  position:absolute;
  line-height: 80px;
  color:${colors.$000000};
  font-family:${fonts.fontFamily.orbitron};
  font-size:90px;
  text-align:center;
  font-weight:${fonts.orbitronFont.etc1b.weight};
  animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s;
  -moz-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; /* Firefox */
  -webkit-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; /* Safari and Chrome */
  -o-animation: ${props => props.$isview ? "fadein" : "fadeout"} 3s; /* Opera */
`;

export default Information