import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import MarqueeText from './MarqueeText';
import NavBar from './NavBar';
import { colors, fonts } from '../constants';
import Logo from './Logo';
import MobileNavBar from './MobileNavBar';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ isVisible, changeHeader, headerRef, setPageName }) => {

    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [mobileNav, setMobileNav] = useState(false);
    // const [isBg, setIsBg] = useState(location.pathname == "/about");
    const [isBg, setIsBg] = useState(true);

    const onClickMenuBox = () => {
        if (window.innerWidth <= 1024) {
            setMobileNav(!mobileNav)
        } else {
            setIsOpen(!isOpen)
        }
    }

    const handleResize = () => {
        if (window.innerWidth <= 1024) {
            if (isOpen) {
                setIsOpen(!isOpen)
                setMobileNav(!mobileNav)
            }
        } else {
            if (mobileNav) {
                setIsOpen(!isOpen)
                setMobileNav(!mobileNav)
            }
        }
    }


    useEffect(() => {
        if(mobileNav){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'visible';
        }
    },[mobileNav])

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen, mobileNav]);

    // const aboutScroll = () => {
    //     if (window.scrollY >= 1) {
    //         setIsBg(false)
    //     } else if (window.scrollY == 0) {
    //         setIsBg(true)
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', aboutScroll);
    //     return () => {
    //         window.removeEventListener('scroll', aboutScroll);
    //     };
    // }, []);

    useEffect(() => {
        setIsOpen(false)
        setMobileNav(false)
        setPageName(location.pathname)
        // setIsBg(location.pathname == "/about");
        // aboutScroll()
    },[location])


    return (
        <AllContainer $mobilenav={mobileNav?true:false} $isbg={isBg} $changeheader={changeHeader}>
            <Container ref={headerRef}>
                <Left $mobilenav={mobileNav?true:false}>
                    <Link to={"/"}>
                    <Logo changeHeader={changeHeader} />
                    </Link>
                </Left>
                <Middle >
                    <MarqueeText text={mobileNav ? "" : "Mammoth Entertainment is a company that always looks for new and innovative items and will strive for fun and exciting companies, grow to express the best abilities of all artists in the same industry through the best technology and public relations marketing, and as a company based on IT, we will do our best to become a global entertainment that can move forward both domestically and globally."} isVisible={isVisible} />
                </Middle>
                <Right>
                    <NavBar mobileNav={mobileNav} changeHeader={changeHeader} onClickMenuBox={onClickMenuBox} isOpen={isOpen} />
                </Right>
            </Container>
            <MobileNavBar mobileNav={mobileNav} />
        </AllContainer>
    )
}

const AllContainer =  styled.div`
    width : 100vw;
    height : auto;
    z-index: 99;
    position: fixed;
    background: ${props => !props.$changeheader ?"linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0))" : ""};
    // background: ${props => !props.$isbg && !props.$changeheader ?"linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0))" : ""};
    @media ${props => props.theme.minSmallDesktop} {
        background : ${props => props.$mobilenav ? "rgba(0, 0, 0, 0.90)" : ""}
    }
`;

const Container = styled.div`
    padding : 25px 60px;
    width: 100vw;
    height : 100%;
    gap : 106px;
    display:flex;
    align-items:center;
    justify-content: space-between;
    z-index: 3;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 10px 10px 10px 20px;
        gap : 0px;
    }
`;

const Left = styled.div`
    visibility:${props => props.$mobilenav ? "hidden" : "visible"};
    height: 100%;
    display:flex;
    align-items:center;
    cursor: pointer;
    width : 182px;
    @media ${props => props.theme.minSmallDesktop} {
        width : 42px;
        margin-right : 20px;
    }
`;

const Middle = styled.div`
    flex : 7;
    width : 100%;
    height: 100%;
    display:flex;
    align-items:center;
    overflow : hidden;
`;

const Right = styled.div`
    width : 68px;
    height : 68px;
    display  :flex;
    justify-content : right;
    align-items:center;
    cursor: pointer;
    position : relative;
    @media ${props => props.theme.minSmallDesktop} {
        width : 40px;
        height : 40px;
        margin-left : 10px;
    }

`;


export default Header