import React, { useState } from 'react'
import Slider from 'react-slick';
import { styled } from 'styled-components'

import "./slickMo.css";
import "slick-carousel/slick/slick-theme.css";
import Card from './Card';
import { colors, fonts } from '../../../constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper/modules";
import 'swiper/css';


const SlickMobile = ({ list }) => {
    const [nowId, setNowId] = useState(13);

    const onOverMouse = (id) => {
        setNowId(id)
    }

    return (
        <OutContainer>
            <AllContainer>
                <TextContainer>
                    <Title>Our Artist</Title>
                    <Content>
                        <Text>
                            MAMMOTH&nbsp;Entertainment의&nbsp;아티스트들은
                        </Text>
                        <Text>
                            <TopText>
                                뛰어난&nbsp;기술력과&nbsp;홍보마케팅을&nbsp;통해&nbsp;최고의&nbsp;능력을&nbsp;
                            </TopText>
                            <BottomText>
                                발휘&nbsp;할&nbsp;수&nbsp;있도록&nbsp;성장하고&nbsp;있습니다.
                            </BottomText>
                        </Text>
                    </Content>
                </TextContainer>
                <Container>
                    <BgContainer />
                    <SwiperContainer>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1.7}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{ delay: 1000, disableOnInteraction: false }}
                            style={{ overflow: "unset" }}
                            modules={[Autoplay]}
                        >
                            {
                                list.map((item, index) => (
                                    <SwiperSlide key={index} >
                                        <MobileCard>
                                            <Card item={item} onOverMouse={onOverMouse} nowId={nowId} />
                                        </MobileCard>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </SwiperContainer>
                </Container>
            </AllContainer>
        </OutContainer>

    );
}

const OutContainer = styled.div`
    width: 100%;
    height: 100vh;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:${colors.$000000};
    padding: 4% 0 0 0;
    @media ${props => props.minSmallDesktop} {
        height: 100%;
        /* padding: 5rem 0; */
    }
`;

const AllContainer = styled.div`
    width: 100%;
    /* height: 100%; */
    background-color:${colors.$000000};
    padding-top : 40px;
    margin-bottom : 10%;
`;

const TextContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:${colors.$ffffff};
    /* padding: 9% 0 0 0; */
    div{
        /* width: 89%; */
        text-align:center;
        line-height: 27px;
    }
`;

const Title = styled.div`
    font-family:${fonts.fontFamily.orbitron};
    font-size:${fonts.orbitronFont.etc2b.size};
    font-weight:${fonts.orbitronFont.etc2b.weight};
    margin-bottom : 25px;

    @media ${props => props.minSmallDesktop} {
        font-size:${fonts.orbitronFont.etc5b.size};
    }

`;

const Content = styled.div`
    font-family:${fonts.fontFamily.pretendard};
    font-size:${fonts.pretendardFont.default3r.size};
    font-weight:${fonts.pretendardFont.default3r.weight};
    color:${colors.$aaaaaa};

    display : flex;
    flex-direction : column;
    gap : 0px;
    
    @media ${props => props.minSmallDesktop} {
        font-size:${fonts.pretendardFont.default4r.size};
        font-weight:400;
        /* font-weight:${fonts.pretendardFont.default4r.weight}; */
    }
`;

const Text = styled.div``;

const TopText = styled.div``;

const BottomText = styled.div``;

const Container = styled.div`
    width: 100%;
    margin-top : 5rem;
    /* z-index : 1; */
    position:relative;
    align-items:center;
    justify-content:center;
`;

const SwiperContainer = styled.div`
    transform:rotate(6deg);
`;

const MobileCard = styled.div`
    width: 55vw;
    height: 60vw;
    transform: rotateY( 180deg );

    @media ${props => props.theme.minSmallDesktop} {
        /* width: 42.5vw;
        height: 42.5vw; */
    }
`

const BgContainer = styled.div`
    border-radius: 1200px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(134, 34, 196, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    position:absolute;
    width : 80vw;
    height : 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(50px);
`;


export default SlickMobile