import React from 'react'
import { styled } from 'styled-components'
import { colors, fonts } from '../../../constants';
import { ReactComponent as FileUploadImg } from "../../../assets/images/svg/ico_fileUpload.svg"
import { ReactComponent as Trash } from "../../../assets/images/svg/_레이어_1.svg"


const FileUpload = ({ onChange, files }) => {
    return (
        <AllContainer>
            <InputFileComponents type='file' placeholder='파일업로드' id='inputFile' onChange={(e) => { onChange(e.target.files[0]) }} />
            {
                files.name == "" && files.buffer == "" ?
                    <InputLabel htmlFor="inputFile">
                        <FileUploadIcon />
                        <InputLabelText>
                            파일업로드
                        </InputLabelText>
                    </InputLabel>
                    :
                    <FileReUploadWrap>
                        <FileReUploadFileNameWrap>
                            <FileReUploadFileName>{files.name}</FileReUploadFileName>
                            <FileReUploadFileIconCircle onClick={(e) => onChange("")}>
                                <FileReUploadFileIcon />
                            </FileReUploadFileIconCircle>
                        </FileReUploadFileNameWrap>
                        <FileReUploadButton htmlFor="inputFile">파일 재업로드</FileReUploadButton>
                    </FileReUploadWrap>
            }
        </AllContainer>
    )
}

const AllContainer = styled.div`
    width : 100%;
    height : auto;
    padding : 15px 0;
    background-color :${colors.$121212};
    border-radius: 20px;
    display : flex;
    justify-content : center;
    align-items : center;
    gap : 20px;
    @media ${props => props.theme.minSmallDesktop} {
        border-radius: 15px;
    }
`;

const InputFileComponents = styled.input`
    display : none;
`;

const FileUploadIcon = styled(FileUploadImg)`
    height : 100%;
`;

const InputLabel = styled.label`
    cursor: pointer;
    height : auto;
    display : flex;
    justify-content : center;
    align-items : center;
    gap : 20px;

`;

const InputLabelText = styled.div`
    font-family : ${fonts.fontFamily.pretendard};
    font-size : 16px;
    font-weight : 700;
    color :${colors.$aaaaaa};
`;

const FileReUploadWrap = styled.div`
    width : 100%;
    padding : 0px 40px;
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 0px 20px;
        flex-direction : column;
        gap : 10px;
    }

`;

const FileReUploadFileNameWrap = styled.div`
    display : flex;
    flex-direction : row;
    align-items:center;
    gap : 10px;
`;

const FileReUploadFileName = styled.div`
    font-family : ${fonts.fontFamily.pretendard};
    font-size : ${fonts.pretendardFont.default4m.size};
    font-weight : ${fonts.pretendardFont.default4m.weight};
    color :${colors.$aaaaaa};
    @media ${props => props.theme.minSmallDesktop} {
        width : 100%;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
`;

const FileReUploadFileIconCircle = styled.div`
    width : 24px;
    height : 24px;
    background-color :${colors.$444444};
    display : flex;
    align-items:center;
    justify-content:center;
    border-radius : 50px;
    cursor: pointer;
`;

const FileReUploadFileIcon = styled(Trash)``;

const FileReUploadButton = styled.label`
    font-family : ${fonts.fontFamily.pretendard};
    font-size : 16px;
    font-weight : 700;
    color :${colors.$ffffff};
    padding : 27px 56px;
    border : 2px solid ${colors.$ffffff};
    border-radius: 20px;
    display : flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    @media ${props => props.theme.minSmallDesktop} {
        padding : 15px 7px;
        border-radius: 90px;
    }

`;




export default FileUpload